.form-field__inner {
  position: relative;
  display: flex;
  width: 100%;
}
.form-field-info {
  @media screen and (max-width: 599px) {

    padding: 0 1rem;
    &:first-child {
      padding-top: 1rem;
    }
  }
}
.form-field__copy {
  display: flex;
  align-items: center;
  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
  .button-with-icon {
    margin-left: 1rem;
    @media screen and (max-width: 599px) {
      margin-left: .25rem;
    }
    svg {
      left:50%;
      color: #9e9e9e;
    }
    &:hover {
      svg {
        color: #651fff;
      }
    }
  }
}

.form-field__label {
  display: block;
  font-size: 13px;
  line-height: 20px;
  color: $color-default-black;
  margin-bottom: 5px;
}

.form-field input,
.form-field textarea {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: 6px;
  padding: 10px 15px 11px 15px ;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color-default-black;
  background-color: $color-default-white;
  transition: all $default-transition-settings;
  outline: none;

  &::placeholder {
    opacity: 1;
    color: $color-suva-grey;
  }

  &:focus,
  &:hover {
    border-color: $color-han-purple;
  }
}

.form-field input {
  height: 43px;
}

.form-field svg + input {
  padding-left: 47px;
}

.form-field svg {
  position: absolute;
  top: 50%;
  left: calc(50px / 2); // 43px, левый паддинг инпута
  transform: translate(-50%, -50%);
  pointer-events: none;
  color: $color-han-purple;
}

.form-field--resizable textarea {
  min-height: 155px;
  max-height: 300px;
  resize: vertical;
}

.form-field textarea {
  padding-top: 13px;
}

// .form-field.is-invalid input,
// .form-field.is-invalid textarea {
// }

.form-field.errors input {
  background-color: $color-tutu;
  box-shadow:
    0 4px 4px rgba(255, 196, 196, 0.04),
    0 4px 12px rgba(255, 196, 196, 0.24);
}

.form-field__error-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-valencia;
  margin: 0;
  padding: 0 10px;
}

.form-field--public {
  input,
  textarea {
    border: 2px solid $color-transparent;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 24px;
    color: $color-lucky-point;
    box-shadow: 0 4px 20px rgba($color-solitude, 0.6);
    padding-left: 12px;
    border-radius: 10px;

    &:focus {
      border: 2px solid $color-lucky-point;
    }

    &::placeholder {
      color: $color-wild-blue-yonder;
    }
  }

  textarea {
    padding-top: 6px;
  }

  input {
    height: 40px;
  }
}