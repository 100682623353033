.data-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.data-item__btn {
  font-size: 14px;
  letter-spacing: 0.4px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 20px;
}

.data-item__name {
  position: relative;
  padding-right: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-item__name-ic {
  position: absolute;
  color: $color-han-purple;
  width: 16px;
  height: 15px;
  right: 0;
  top: 3px;
}
