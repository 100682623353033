.leave-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -4px;
  padding-bottom: 80px;

  form {
    max-width: 313px;
  }

  .radio-rating {
    margin-bottom: 12px;
  }

  .form-field__inner textarea {
    height: 88px;
  }

  &__caption {
    margin: 0;
    margin-bottom: 20px;
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $color-wild-blue-yonder;
  }

  &__field {
    margin-bottom: 6px;
  }

  .button {
    margin-top: 10px;
  }
}
