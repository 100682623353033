.form-profile form {
  display: flex;

  @include viewport--xsm {
    flex-direction: column;
  }
}

.form-profile__fieldset-wrapper {
  padding-top: 12px;
  border-top: 1px solid $border-color;
  margin-bottom: 26px;

  @include viewport--md {
    margin-bottom: 24px;
  }
}

.form-profile fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.form-profile__fieldset-legend {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: $color-default-black;
  margin-bottom: 15px;

  &--contacts {
    margin-bottom: 17px;
  }
}

.form-profile__user-picture {
  order: 2;
  margin-left: 32px;

  @include viewport--xsm {
    order: 0;
    margin-left: 0;
    margin-bottom: 82px;
  }
}

.form-profile__text-input-wrapper {
  width: 100%;
  max-width: 613px;

  @include viewport--md {
    max-width: 628px;
  }

  @include viewport--xsm {
    padding: 0 16px 20px;
  }
}

.form-profile__form-field {
  max-width: 387px;
  margin-bottom: 16px;

  @include viewport--xsm {
    max-width: none;
  }
}

.form-profile__form-field--icon input {
  @include viewport--xsm {
    padding-top: 16px;
    padding-bottom: 17px;
    height: 56px;
  }
}

.form-profile__button-link-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -16px;

  @include viewport--xsm {
    margin: 0 -8px;
  }
}

.form-profile__button {
  max-width: 148px;
  margin: 10px 16px;
  &-long {
    max-width: 200px;
  }

  &:disabled {
    color: $color-default-white;
    background-color: $color-silver;
    pointer-events: none;
  }

  svg {
    margin-right: 6px;
  }

  @include viewport--xsm {
    margin: 8px;
  }
}

.form-profile__link {
  padding: 0;
  margin: 10px 16px;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;

  @include viewport--xsm {
    margin: 8px;
  }
}
