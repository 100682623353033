.calendar-schedule-day {
  position: relative;
  display: flex;

  height: 100%;
  max-height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

.calendar-schedule-day__hours {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 60px;
  padding-left: 12px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: $color-default-white;

  @include viewport--sm {
    padding-left: 15px;
  }
}

.calendar-schedule-day__time {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: $color-charcoal;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
}

.calendar-schedule-day__table {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
}

.calendar-schedule-day__table-row {
  position: relative;
  min-height: 21px;
  border-left: 1px solid $color-gainsboro;
  border-bottom: 1px solid $color-transparent;
  background-image: linear-gradient(135deg, $color-whisper 25%, $color-default-white 25%, $color-default-white 50%, $color-whisper 50%, $color-whisper 75%, $color-default-white 75%, $color-default-white 100%);
  background-size: 16px 16px;

  &:nth-child(2n) {
    border-bottom: 1px solid $color-gainsboro;
    background-position: top 0 left 11px;

    @include viewport--md {
      background-position: top 0 left 10px;
    }
  }
}

.calendar-schedule-day__table-row--working {
  background-image: none;
  background-color: $color-default-white;
}

.calendar-schedule-week__current-time.current-time {
  @include viewport--sm {
    display: none;
  }
}
