.button-toggle {
  display: flex;
}

.button-toggle__btn {
  @include reset-btn;

  width: 50%;
  max-width: 172px;
  padding: 6px;
  font-family: $font-family--base;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: $color-han-purple;
  background-color: $color-default-white;
  border: 1px solid $color-silver;
  outline: none;
  background-position: center;
  transition:
    background-color $default-transition-settings,
    color $default-transition-settings,
    border-color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-silver, 0.7);
      border-color: transparent;
    }
  }
}

.button-toggle__btn.active {
  color: $color-default-white;
  background-color: $color-han-purple;
  border-color: $color-han-purple;
  box-shadow: 0 4px 5px rgba(178, 177, 177, 0.25);

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-han-purple, 0.7);
      border-color: transparent;
    }
  }
}

.button-toggle__btn--left,
.button-toggle__btn--left.active {
  border-radius: 4px 0 0 4px;
  border-right: 1px solid transparent;
}

.button-toggle__btn--right,
.button-toggle__btn--right.active {
  border-left: 1px solid transparent;
  border-radius: 0 4px 4px 0;
}
