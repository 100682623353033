.intro {
  width: 100%;
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
  padding: 70px 30px;
  background-color: $color-magnolia-2;
  background-image: url("../img/svg/bg-intro.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 1200px) and (max-height: 650px) {
    padding: 18px 20px;
  }

  @media (max-width: 1023px) and (min-height: 651px) {
    padding: 28px 20px;
  }

  @media (min-width: 768px) and (min-height: 768px) {
    padding: 70px 30px;
  }

  // @include viewport--md {
  //   padding: 28px 20px;
  // }

  // @media (max-width: 1200px) and (max-height: 650px) {
  //   padding: 18px 20px;
  // }

  @include viewport--sm {
    padding: 18px 10px;
    background-position: bottom -100px right 0;
  }
}
