.group-list {
  &__title {
    display: flex;
    margin: 0;
    padding-bottom: 6px;
    font-family: $font-family--golos;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-default-white;
    border-bottom: 1px solid rgba($color-default-white, 0.45);
  }

  &__today {
    margin-right: 4px;
    padding: 1px 8px;
    background-color: $color-purple-heart;
    border-radius: 5px;
  }

  &__current-day {
    margin-left: 4px;
    color: rgba($color-default-white, 0.45);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
