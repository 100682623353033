.registration__form {
  padding: 0 32px;
  padding-top: 40px;
  padding-bottom: 88px;

  @include viewport--sm {
    padding-left: 25px;
    padding-right: 25px;
  }

  form {
    display: flex;
    flex-direction: column;
  }
}

.registration__form-input {
  margin-bottom: 30px;
}

.registration__form-input.hide {
  display: none;
}

.registration__btn-submit {
  margin-top: 40px;
}
