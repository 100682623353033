.tag-admin {
  background-color: #f0e9ff;
  color: $color-han-purple;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border-radius: 100px;
  padding: 5px 10px 6px 12px;
  transition: all $default-transition-settings;
}

.tag-admin--btn:focus-within,
.tag-admin--btn:hover {
  color: #f0e9ff;
  background-color:$color-han-purple;
  cursor: pointer;
}

.tag-admin--btn:focus-within button,
.tag-admin--btn:hover button {
  color: #f0e9ff;
}

.tag-admin__btn {
  @include reset-btn();

  color: $color-han-purple;
  padding-left: 2px;
  background-color: transparent;
  transition: all $default-transition-settings;
}

.tag-admin span {
  padding-right: 3px;
  white-space: nowrap;
}
