.calendar-days {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  display: flex;
  min-height: 37px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 60px;
  border-bottom: 1px solid $color-gainsboro;
  background-color: $color-default-white;

  @include viewport--md {
    top: 0;
    min-height: 32px;
    padding-top: 3px;
    padding-bottom: 3px;
  }


  @include viewport--sm {
    display: none;
    // position: relative;
    // flex-direction: column;
    // // flex-shrink: 0;
    // height: 100%;
    // min-height: auto;
    // max-height: 100%;
    // width: 63px;
    // padding: 0;
    // padding-top: 30px;
    // padding-bottom: 30px;
    // border-bottom: none;
    // border-right: 1px solid $color-gainsboro;
  }
}

.calendar-days__weekday {
  display: flex;
  align-items: center;
  width: calc(100% / 7);
  padding: 0 5px;

  // @include viewport--lg {
  //   padding: 0 5px;
  // }

  @include viewport--md {
    width: 100%;
    padding: 0;
    padding-left: 15px;
  }

  &:not(:last-child) {
    @include viewport--sm {
      margin-bottom: 68px;
    }
  }
}

.calendar-days__weekday-info {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;

  @include viewport--sm {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
      width: 4px;
      height: 30px;
      background-color: $color-default-white;
      transition: background-color $default-transition-settings;
    }
  }

  .calendar-days__weekday-today {
    display: none;
  }
}

.calendar-days__weekday-info--today {
  @include viewport--sm {
    &::before {
      background-color: $color-han-purple;
    }
  }

  .calendar-days__weekday-today {
    display: inline-block;

    @include viewport--md {
      display: none;
    }
  }

  .calendar-days__weekday-number {
    @include viewport--md {
      color: $color-han-purple;
    }
  }

  .calendar-days__weekday-name {
    @include viewport--md {
      color: $color-han-purple;
    }
  }
}

.calendar-days__weekday-number {
  margin-right: 5px;
  color: $color-charcoal;

  // @include viewport--lg {
  //   margin-right: 5px;
  // }

  @include viewport--md {
    margin-right: 3px;
  }

  @include viewport--sm {
    margin-right: 10px;
  }
}

.calendar-days__weekday-name {
  color: $color-grey;
}

.calendar-days__weekday-today {
  margin-left: 5px;
  color: $color-grey;
}
