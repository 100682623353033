.menu-sidebar {
  flex-shrink: 0;
  flex-grow: 0;

  height: 100%;
  max-height: 100%;
}

.menu-sidebar__inner {
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  width: 327px;
  border-right: 1px solid $border-color;
  background: $color-default-white;

  flex-direction: column;
  justify-content: space-between;

  display: none;

  .menu-sidebar.active & {
    display: flex;
  }

  @include viewport--lg {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 53px;
    height: auto;
    z-index: 7;
    pointer-events: auto;

    transform: translateX(-100%);
    transition: transform $default-transition-settings;

    .menu-sidebar.active & {
      transform: translateX(0);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.039), 0 7px 24px rgba(0, 0, 0, 0.19);
    }
  }

  @media (max-width: 350px) {
    width: 100%;
  }
}

.menu-sidebar__calendar {
  border-top: 1px solid $border-color;
  padding: 10px;
}

.menu-sidebar__overlay {
  @include viewport--lg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity $default-transition-settings;
    background: rgba($color-grey, 0.8);
    z-index: 6;

    .menu-sidebar.active & {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.menu-sidebar__toggle {
  @include reset-btn;

  position: absolute;
  width: 36px;
  height: 36px;
  left: 10px;
  top: 8px;
  z-index: 8;
  color: $color-default-white;
  border-radius: 50%;
  background-color: transparent;

  .menu-sidebar__toggle-ic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, - 50%);
  }

  &.no-pointer {
    pointer-events: none;
  }

  @include viewport--sm {
    color: $color-gainsboro;
    z-index: 5;
  }
}

.menu-sidebar__toggle--close {
  display: none;

  @include viewport--sm {
    display: block;
    color: $color-default-white;
  }
}

.menu-sidebar__toggle--toggle {
  .menu-sidebar__toggle-ic--close {
    display: none;
  }

  .menu-sidebar__toggle-ic--open {
    display: block;
  }

  &[aria-pressed="true"] {
    .menu-sidebar__toggle-ic--close {
      display: block;
    }

    .menu-sidebar__toggle-ic--open {
      display: none;
    }

    @include viewport--sm {
      .menu-sidebar__toggle-ic--close {
        display: none;
      }

      .menu-sidebar__toggle-ic--open {
        display: block;
      }
    }
  }
}

.menu-sidebar__menu {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
}

.menu-sidebar__item {
  padding-right: 23px;
  margin-bottom: 4px;
}

.menu-sidebar__link {
  @include reset-btn;
  // position: relative;
  width: 100%;
  text-align: left;
  display: block;
  border-radius: 0 30px 30px 0;
  background: $color-default-white;
  transition: background $default-transition-settings;
  padding: 10px 16px 10px 64px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  position: relative;

  &--active {
    background: $color-magnolia;
    pointer-events: none;
    color: $color-han-purple;
  }
}

.menu-sidebar__link svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
}

.menu-sidebar__link-text {
  position: relative;
  display: inline-block;
  padding-right: 25px;
}

.menu-sidebar__count {
  position: absolute;
  left: calc(100% - 16px);
  top: 50%;
  transform: translateY(-50%);
  min-width: 16px;
  min-height: 16px;
  padding: 4px;
  color: $color-default-white;
  background-color: $color-han-purple;
  font-size: 11px;
  line-height: 8px;
  text-align: center;
  border-radius: 14px;

}
