span.ripple-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: ripple-2 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple-2 {
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

