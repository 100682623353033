.event {
  &__time {
    display: flex;
    align-items: flex-start;
  }

  &__icon {
    display: flex;
    align-items: center;
    color: $color-lucky-point;
    margin-right: 6px;
  }

  &__location {
    display: flex;
    align-items: flex-start;
    padding-left: 22px;
  }

  &__address {
    margin: 0;
    margin-right: 8px;
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 16px;
    color: $color-wild-blue-yonder;
  }

  &__map {
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 16px;
    color: $color-medium-slate-blue;
    transition: color $default-transition-settings;

    &:hover,
    &:focus {
      color: $color-purple-heart;
      transition: color $default-transition-settings;
    }
  }
}
