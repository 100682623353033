.btn-delete {
  @include reset-btn;
  color: $color-night-rider;
  border-radius: 4px;
  padding: 8px;
  transition: color $default-transition-settings;

  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
    color: $color-red-orange;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: rgba($color-han-purple, 0.7);
    }
  }
}
