.personal-group-menu {
  position: relative;
}

.personal-group-menu__button {
  display: none;
  transition: all $default-transition-settings;

  @include viewport--md {
    @include reset-btn();

    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px;

    font-family: $font-family--alt;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $color-default-black;

    // &:hover,
    // &:focus {
    //   color: rgba($color-han-purple, 0.7);
    // }

    svg {
      margin-left: 7px;
    }
  }
}

.personal-group-menu__list {
  display: flex;
  align-items: baseline;
  margin-left: -5px;
  margin-right: -5px;
  list-style: none;
  margin: 0;
  padding: 0;

  @include viewport--md {
    display: none;
    position: absolute;
    left: 5px;
    top: 37px;
    width: 100%;
    z-index: 5;
    flex-direction: column;
    margin: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 4px;
    background-color: $color-default-white;
    box-shadow: 0px 0.25px 3px rgba(0, 0, 0, 0.039), 0px 2.75px 9px rgba(0, 0, 0, 0.19);
  }
}

.personal-group-menu.active {
  @include viewport--md {
    .personal-group-menu__list {
      display: flex;
    }
  }
}

.personal-group-menu__item {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;

  @include viewport--md {
    margin: 0;
  }
}

.personal-group-menu__option {
  @include reset-btn();

  display: block;
  width: 100%;
  padding: 5px;
  padding-bottom: 20px;
  border-radius: 4px;
  font-family: $font-family--alt;
  font-weight: 700;
  font-size: 20px;
  text-align: left;
  line-height: 28px;
  color: $color-default-black;
  transition: all $default-transition-settings;


  @include viewport--md {
    font-family: $font-family--base;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px;
    border-radius: 0;

    &:hover,
    &:focus {
      color: $color-default-black;
      background-color: $color-white-smoke;
    }
  }
}

.personal-group-menu__option.active {
  position: relative;
  color: $color-han-purple;
  // border-bottom: 3px solid $color-han-purple;
  pointer-events: none;

  &::after {
    position: absolute;
    content: "";
    width: calc(100% - 10px);
    height: 3px;
    background-color: $color-han-purple;
    left: 5px;
    bottom: 1px;
  }

  @include viewport--md {
    color: $color-default-black;
    // border-bottom: none;
    background-color: $color-white-smoke;

    &::after {
      display: none;
    }
  }
}
