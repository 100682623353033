.profile-services {
  margin: 0;
  margin-right: -20px;
  margin-bottom: -20px;
  padding: 0;
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  @include viewport--lg {
    width: 100%;
    flex-direction: column;
  }

  &__item {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;

    &:last-child {
      @include viewport--lg {
        margin-bottom: 0;
      }
    }

    @include viewport--lg {
      width: 100%;
      margin-right: 0;
    }
  }

  &__description {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 16px;
    font-family: $font-family--golos;
    font-size: 13px;
    line-height: 17px;
    color: $color-wild-blue-yonder;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__element {
    padding-top: 8px;
    margin-bottom: 8px;
    border-top: 1px solid rgba($color-wild-blue-yonder, 0.1);

    @include viewport--sm {
      padding-top: 8px;
      margin-bottom: 8px;
    }
  }

  .tag-list {
    margin-top: 8px;

    @include viewport--sm {
      margin: 7px 0;
    }
  }

  .button {
    margin-top: 12px;
  }
}
