.login__socials {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 32px;
  background-color: $color-snow;

  @include viewport--sm {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.login__socials-desc {
  margin: 0;
  margin-right: 20px;
  font-size: 13px;
  line-height: 20px;

  @include viewport--sm {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.login__socials-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.login__socials-item {
  &:not(:first-child) {
    margin-left: 8px;
  }
}

.login__form {
  padding: 0 32px;
  padding-top: 24px;
  padding-bottom: 88px;

  @include viewport--sm {
    padding-left: 25px;
    padding-right: 25px;
  }

  form {
    display: flex;
    flex-direction: column;
  }
}

.login__form-input {
  margin-bottom: 30px;
}

.login__btn-submit {
  margin-top: 40px;
}
