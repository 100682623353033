.tech-sidebar {
  background: $color-default-white;
  width: 375px;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 53px;
  transform: translateX(100%);
  transition: transform $default-transition-settings;
  padding-top: 57px;
  padding-bottom: 96px;

  &.active {
    transform: translateX(0);
    box-shadow: 0 4px 30px rgba(67, 67, 67, 0.25);
    z-index: 6;
  }

  @include viewport--sm {
    top: 0;
    width: 100%;

    &.active {
      z-index: 9;
    }
  }
}

.tech-sidebar__top {
  padding: 14px 93px;
  border-bottom: 1px solid $border-color;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $color-default-white;
  height: 57px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tech-sidebar__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: $color-default-black;

  @media (max-width: 359px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.tech-sidebar__btn-back {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;

  &.hover-ripple {
    transform: translateY(-50%) translateZ(0);
  }

  svg {
    margin-right: 9px;
    width: 7px;
    height: 11px;
  }

  @media (max-width: 359px) {
    font-size: 14px;
  }
}

.tech-sidebar__btn-close {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);

  &.hover-ripple {
    transform: translateY(-50%) translateZ(0);
  }
}

.tech-sidebar form {
  height: 100%;
  max-height: 100%;
}

.tech-sidebar__inner {
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 9;
}

.tech-sidebar__inner--with-search {
  padding-top: 64px;
  position: relative;
  overflow-y: hidden;
}

.tech-sidebar__search {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tech-sidebar__data-search {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.tech-sidebar__action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-default-white;
  padding: 24px 15px 32px;
  height: 96px;
}

.tech-sidebar__btn-back--mobile-only {
  display: none;

  @include viewport--sm {
    display: block;
  }
}
