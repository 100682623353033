.field-radio {
  display: flex;

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    cursor: pointer;
    appearance: none;
  }

  input:hover ~ .field-radio__icon,
  input:focus ~ .field-radio__icon {
    &::after {
      opacity: 1;
    }
  }

  input:checked ~ .field-radio__icon {
    svg {
      opacity: 1;
      visibility: visible;
    }
  }
}

.field-radio__icon {
  position: relative;
  display: inline-flex;

  svg {
    color: $color-han-purple;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
      opacity $default-transition-settings,
      visibility $default-transition-settings;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $color-han-purple;
    // background-color: transparent;
    // transition: background-color $default-transition-settings;
  }

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba($color-han-purple, 0.2);
    opacity: 0;
    transition: opacity $default-transition-settings;
  }
}

.field-radio__label {
  position: relative;
  padding-left: 8px;
  font-size: 14px;
  line-height: 16px;
  color: $color-night-rider;
}
