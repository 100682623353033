.empty-list {
  min-height: 64px;
  border-bottom: 1px solid $border-color;
  padding: 22px 16px 24px;
  text-align: center;

  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;

  p {
    margin: 0;
  }
}
