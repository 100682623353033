.user-review {
  padding: 32px;
  padding-top: 24px;
  background: $color-default-white;
  box-shadow: 0 4px 20px rgba($color-solitude, 0.6);
  border-radius: 10px;

  @include viewport--sm {
    padding: 24px;
    padding-top: 26px;
  }

  @include viewport--xxsm {
    padding: 14px;
    padding-top: 16px;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 18px;
    color: $color-lucky-point;
  }

  &__text {
    @include viewport--sm {
      position: relative;
      max-height: 236px;
      overflow-y: hidden;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(to top, #FFFFFF 0%, $color-transparent 100%);
      }
    }
  }

  .rating {
    margin-top: 8px;
    margin-bottom: 14px;

    &__caption {
      font-size: 13px;
    }
  }

  .paragraph {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
