.social-btn {
  @include reset-btn;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  outline: none;
  background-color: $color-default-white;
  overflow: hidden;

  svg {
    position: relative;
    z-index: 1;
  }
}
