.rating {
  display: flex;
  align-items: center;

  &__stars {
    display: flex;
    margin-left: -1px;
    margin-right: 11px;
  }

  &__star {
    display: flex;
    align-items: center;
    margin-left: 1px;
    margin-right: 1px;
    color: $color-hawkes-blue;

    &.is-colored {
      color: $color-orange;
    }
  }

  &__caption {
    margin: 0;
    flex-shrink: 0;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 18px;
    color: $color-wild-blue-yonder;

    @include viewport--sm {
      font-size: 13px;
      line-height: 17px;
    }
  }
}

.rating__stars--han-purple {
  .rating__star {
    &.is-colored {
      color: $color-han-purple;
    }
  }
}

.rating--spaced-stars {
  .rating__stars {
    margin-left: -2px;
    margin-right: -2px;
  }
  .rating__star {
    margin-left: 2px;
    margin-right: 2px;
  }
}


.rating--large {
  .rating__star {
    svg {
      width: 31px;
      height: 29px;
    }
  }
}
