.header {
  background: $color-han-purple;
  color: $color-default-white;
  padding: 11px 16px 12px 53px;
  position: fixed;
  height: 53px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 7;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include viewport--lg {
    pointer-events: auto;
  }

  @include viewport--sm {
    position: absolute;
    width: 327px;
    z-index: 8;

    transform: translateX(-100%);
    transition: transform $default-transition-settings;

    .menu-sidebar.active & {
      transform: translateX(0);
    }
  }

  @media (max-width: 350px) {
    width: 100%;
  }
}

.header__navigation {
  max-width: 244px;
  position: relative;

  @include viewport--sm {
    max-width: 140px;
  }
}

.header__navigation-title {
  @include reset-btn;
  color: $color-default-white;
  padding: 8px 32px 8px 8px;
  border-radius: 4px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.005em;
  background-color: $color-han-purple;

  svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform $default-transition-settings;
  }

  .header__navigation.active & {
    svg {
      transform: translateY(-50%) rotate(-180deg);
    }
  }
}

.header__navigation-list {
  list-style: none;
  margin: 0;
  padding: 8px;
  position: absolute;
  top: 35px;
  right: 4px;
  width: 244px;
  background: $color-default-white;
  box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0 14px 24px rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  display: none;

  .header__navigation.active & {
    display: block;
  }
}

.header__navigation-link {
  @include reset-btn;
  text-align: left;
  display: block;
  padding: 8px 8px;
  border-radius: 4px;

  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $color-nero-2;
}

.header__logo {
  display: inline-block;
  width: 105px;
  margin-bottom: 3px;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}
