.button-with-tooltip {
  position: relative;
  display: inline-flex;
}

.button-with-tooltip__btn {
  @include reset-btn;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $color-han-purple;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);
  outline: none;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover + .button-with-tooltip__tooltip {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  svg {
    color: $color-default-white;
  }
}

.button-with-tooltip__tooltip {
  position: absolute;
  bottom: -50px;
  right: -3px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  color: $color-nero-2;
  background-color: $color-default-white;
  border: 1px solid $color-white-smoke;
  border-radius: 4px;
  transform: scale(0);

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  transition:
    opacity $default-transition-settings,
    visibility $default-transition-settings,
    transform $default-transition-settings;

  &::after {
    content: "";
    position: absolute;
    top: -5px;
    right: 17px;
    width: 9px;
    height: 9px;
    background-color: $color-default-white;
    border-top: 1px solid $color-white-smoke;
    border-left: 1px solid $color-white-smoke;
    transform: rotate(45deg);
  }
}
