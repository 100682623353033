.main-content {
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: column;

  //width: 100%;
}

.main-content__top {
  position: relative;
  height: 61px;
  border-bottom: 1px solid $border-color;
  padding: 14px 20px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include viewport--sm {
    height: 53px;
    padding: 7px 12px 7px 48px;
    box-shadow: 0 0.5px 1.75px rgba(0, 0, 0, 0.039), 0 1.85px 6.25px rgba(0, 0, 0, 0.19);
    position: relative;
  }

  @include viewport--xsm {
    padding-bottom: 10px;
    height: 56px;
  }
}

.main-content__top--menu {

  @include viewport--xsm {
    padding-bottom: 54px;
    border-bottom: none;
    height: 100px;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 44px;
      background-color: $color-snow;
      box-shadow: inset 0 1.85px 6.25px -2px rgba(0, 0, 0, 0.19);
      left: 0;
      bottom: 0;
    }
  }

  .main-content__tabs-controls {
    @include viewport--sm {
      margin-left: 20px;
    }
    @include viewport--xsm {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      z-index: 1;
      margin: 0;
    }
  }
  .main-content__tabs-btn {
    @include viewport--xsm {
      &:not(.active) {
        background-color: transparent;
      }
    }
  }
}

.main-content__page-title {
  @include viewport--xsm {
    padding-left: 8px;
  }
}

.main-content__top-block {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  display: flex;
  align-items: center;
}

.main-content__tabs-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  margin-right: 40px;

  @include viewport--md {
    margin-left: 10px;
    // margin-right: 10px;
    margin-right: auto;
  }

  @include viewport--sm {
    margin-left: 0;
  }
}

.main-content__tabs-control {
  min-width: 73px;

  @media (max-width: 359px) {
    min-width: 60px;
  }
}

.main-content__tabs-btn {
  width: 100%;
}

.main-content__btn-open-schedule {
  display: none;

  @include viewport--md {
    display: inline-block;
    width: 30%;
    max-width: 185px;
    margin-right: auto;
  }

  @include viewport--sm {
    display: none;
  }
}

.main-content__search {
  position: relative;
  max-width: 465px;
  width: 80%;
  margin-left: auto;
  margin-right: 40px;

  @include viewport--lg {
    max-width: 325px;
    margin-right: 25px;
  }

  @include viewport--md {
    position: absolute;
    top: 14px;
    right: 74px;
    width: auto;
    max-width: none;
    margin-right: 0;
    margin-left: 0;
  }

  @include viewport--sm {
    top: 12px;
    right: 66px;
  }

  @media (max-width: 359px) {
    right: 58px;
  }
}

.main-content__add-btn {
  @include viewport--md {
    margin-left: 15px;
  }
}

.main-content__inner {
  height: calc(100% - 61px);
  max-height: calc(100% - 61px);
  position: relative;
  overflow-y: auto;

  @include viewport--sm {
    height: calc(100% - 53px);
    max-height: calc(100% - 53px);
  }
}

.main-content__inner--menu {
  @include viewport--xsm {
    height: calc(100% - 100px);
    max-height: calc(100% - 100px);
  }
}

.main-content__inner--with-filters {
  overflow-y: visible;
  padding-bottom: 45px;
  padding-top: 65px;

  .page--clients & {
    @include viewport--sm {
      padding-top: 108px;
    }
  }
}

.main-content__inner--week {
  padding-top: 85px;
  overflow-y: visible;

  @include viewport--md {
    padding-top: 32px;
    padding-bottom: 60px;
  }

  @include viewport--sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 55px;
  }
}

.main-content__inner--day {
  padding-top: 48px;
  overflow-y: visible;

  @include viewport--md {
    padding-top: 0;
    padding-bottom: 60px;
  }

  @include viewport--sm {
    padding-top: 55px;
  }
}

.main-content__inner--bg-dimmed {
  background-color: $color-snow;
}

.main-content__inner--with-padding {
  padding: 40px 40px 80px;

  @include viewport--md {
    padding: 40px 20px 40px;
  }

  @include viewport--sm {
    padding: 20px;
  }
}

.main-content__filters-result {
  height: 100%;
  max-height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

.main-content__filters {
  height: 65px;
  border-bottom: 1px solid $border-color;
  padding: 13px 20px 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  display: flex;
  align-items: center;

  > * {
    width: 100%;
  }

  @include viewport--sm {
    padding-left: 16px;
    padding-right: 16px;
  }

  .page--clients & {
    @include viewport--sm {
      height: 108px;
    }
  }

  form {
    display: flex;
    height: 100%;
  }
}


.main-content__filters-footer {
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-content__reviews-list {
  list-style: none;
  padding: 0;
  margin: 40px 0 40px 40px;
  width: 100%;
  max-width: 640px;

  @include viewport--lg {
    max-width: 720px;
    margin: 40px auto;
  }

  @include viewport--sm {
    margin: 20px auto;
    padding: 0 16px;
  }
}

.main-content__reviews-list-item {
  margin-bottom: 16px;

  @include viewport--sm {
    margin-bottom: 20px;
  }
}

.main-content__reviews-list-item:last-child {
  margin-bottom: 0;
}

.main-content__form-profile {
  padding: 40px 40px 80px;

  @include viewport--md {
    padding: 40px 20px 40px;
  }

  @include viewport--sm {
    padding: 20px;
  }

  @include viewport--xsm {
    padding: 0;
  }
}

.main-content__personal-group-menu {
  margin-left: -10px;
  margin-top: 12px;

  @include viewport--md {
    margin-top: 0;
  }

  @include viewport--sm {
    margin-left: 4px;
  }

  @include viewport--xxsm {
    margin-left: -5px;
  }
}
