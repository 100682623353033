.slider-pagination {
  position: absolute;
  margin-top: 20px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;

  @include viewport--sm {
    margin-top: 16px;
  }

  &-bullet {
    margin-right: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba($color-wild-blue-yonder, 0.3);

    &.is-active {
      background-color: $color-medium-slate-blue;
    }
  }
}
