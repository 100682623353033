.rouble {
  font-family: $font-family--rouble;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

// .rouble--input {
//   position: absolute;
//   background-color: transparent;
// }
