.places-filters {
  display: flex;
  align-items: flex-end;
  min-height: 33px;

  > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.places-filters__search {
  width: 344px;

  @include viewport--md {
    margin-right: 0;
    width: auto;
  }
}

.places-filters__search .field-search {
  @include viewport--md {
    position: absolute;
    top: -62px;
    height: 61px;
    right: 74px;
    width: auto;
    max-width: none;

    &.active {
      position: absolute;
      top: -62px;
      left: 0;
      right: 0;
      height: 61px;
      padding: 0 15px;
    }
  }

  @include viewport--sm {
    height: 50px;
    top: -51px;
    right: 66px;

    &.active {
      height: 50px;
      top: -51px;
      right: 0;
    }
  }
}

.places-filters__activity {
  width: 190px;
}
