.profile-header {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include viewport--lg {
    flex-direction: column;
    justify-content: flex-start;
  }

  @include viewport--sm {
    margin-bottom: 20px;
  }

  &__left-col {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    @include viewport--sm {
      align-items: center;
      padding: 20px;
      margin-right: 0;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 4px 20px rgba($color-solitude, 0.6);
      background-color: $color-default-white;
    }
  }

  &--centered {
    margin-bottom: 24px;
    padding-top: 32px;
    flex-direction: column;
  }

  .paragraph {
    margin-top: 6px;

    @include viewport--lg {
      margin-top: 2px;
      text-align: center;
    }
  }

  .rating {
    @include viewport--lg {
      margin-top: 20px;
    }
  }
}
