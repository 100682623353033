.current-time {
  position: absolute;
  top: 620px;
  left: 8px;
  z-index: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: $color-default-white;
  background-color: $color-han-purple;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -100vw;
    z-index: 5;
    transform: translateY(-50%);
    width: 100vw;
    height: 1px;
    background-color: $color-han-purple;
  }
}
