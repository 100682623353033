.custom-input {
  width: 100%;

  input {
    position: relative;
    z-index: 4;
    display: block;
    width: 100%;
    min-height: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0;
    padding-right: 15px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-night-rider;
    background-color: $color-transparent;
    // background-color: $color-white;
    border: none;
    border-bottom: 1px solid $color-silver;
    border-radius: 0;
    outline: none;

    transition: color $default-transition-settings, border $default-transition-settings;

    &:hover,
    &:focus {
      border-color: $color-night-rider;
    }
  }

  input::placeholder {
    color: $color-transparent;
  }

  input + label {
    top: 0;
    left: 0;
    font-size: 16px;
    line-height: 24px;
  }

  input:focus + label {
    top: -15px;
    left: 0;
    font-size: 12px;
    line-height: 16px;

    @include viewport--md {
      font-size: 12px;
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
    // cursor: pointer;
    pointer-events: none;
    transition:
      top $default-transition-settings,
      font-size $default-transition-settings,
      line-height $default-transition-settings;
  }

  &__wrapper {
    position: relative;
  }

  &--select {
    .custom-input__dropdown-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;

      transition: fill $default-transition-settings, transform $default-transition-settings;

      pointer-events: none;

      color: $color-default-black;
      &_right {
        z-index: 6;
        width: 14px;
        height: 14px;
        right: 1rem;
        left: initial;
      }
    }

    .custom-input__dropdown-icon-alt {
      display: none;
    }

    input {
      cursor: pointer;
    }

    .custom-input__select-list {
      position: absolute;
      top: 30px;
      left: 0;
      z-index: 10;

      width: 100%;
      max-height: 304px;
      margin: 0;
      padding: 8px 0;
      overflow-y: auto;

      list-style: none;

      background-color: $color-default-white;
      visibility: hidden;
      opacity: 0;
      //z-index: 100;
      box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      transition: visibility $default-transition-settings, opacity $default-transition-settings;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (max-height: 769px) {
        max-height: 216px;
      }
    }

    .custom-input__select-list.js-visible {
      visibility: visible;
    }

    .custom-input__select-item {
      position: relative;
      // margin: 0 16px;
      padding: 14px 16px;
      min-height: 48px;

      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: rgba($color-default-black, 0.87);
      background-color: $color-transparent;
      outline: none;

      cursor: pointer;

      transition:
        color $default-transition-settings,
        background-color $default-transition-settings;

      @include viewport--xl {
        padding: 14px 18px;
        min-height: 50px;

        font-size: 16px;
        line-height: 21px;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          background-color: rgba($color-default-black, 0.04);
        }

        &:focus {
          background-color: rgba($color-default-black, 0.12);
        }
      }
    }
  }

  &--small {
    input {
      padding: 3px 10px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $color-default-black;
      border-radius: 4px;
      border: none;
      background-color: $color-magnolia;
      transition:
        color $default-transition-settings,
        background-color $default-transition-settings;

      &:hover,
      &:focus {
        color: $color-default-white;
        background-color: $color-han-purple;
      }
    }

    label {
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .custom-input__dropdown-icon {
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .custom-input__select-list {
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
      max-height: 395px;
      width: calc(100% + 18px);

      @media (max-height: 769px) {
        max-height: 258px;
      }
    }

    .custom-input__select-item {
      padding: 12px 16px;
    }
  }

  &--small-alt {
    input {
      // padding: 2px 20px;
      padding: 5px 20px 6px 7px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15px;
      border: none;
      color: $color-default-black;
      border-radius: 0;
      border-bottom: 1px solid $color-transparent;
      background-color: $color-default-white;
      transition: border-color $default-transition-settings;

      &:hover,
      &:focus {
        border-color: $color-whisper;
      }
    }

    label {
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .custom-input__select-list {
      top: 32px;
      left: 0;
      max-height: 395px;
      width: calc(100% + 14px);

      @media (max-height: 769px) {
        max-height: 258px;
      }
    }

    .custom-input__select-item {
      padding: 12px 16px;
    }

    .custom-input__dropdown-icon {
      z-index: 7;
      right: 5px;
      width: 10px;
      height: 5px;
    }

    .custom-input__dropdown-icon-base {
      display: none;
    }

    .custom-input__dropdown-icon-alt {
      display: inline-flex;
    }
  }

  &--public {
    padding: 0 16px;

    input {
      padding: 8px 20px 10px 29px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      font-family: $font-family--golos;
      letter-spacing: normal;
      border: none;
      color: $color-lucky-point;
      border-radius: 5px;
      border-bottom: 1px solid $color-transparent;
      background-color: $color-default-white;
      transition: border-color $default-transition-settings;

      &:hover,
      &:focus {
        border-color: $color-whisper;
      }
    }

    label {
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .custom-input__label-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      z-index: 7;

      svg {
        color: $color-wild-blue-yonder;
      }
    }

    .custom-input__select-list {
      top: 42px;
      left: 0;
      max-height: 395px;
      width: 100%;
      padding: 0 12px;

      @media (max-height: 769px) {
        max-height: 258px;
      }
    }

    .custom-input__select-item {
      min-height: 32px;
      padding: 8px 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      font-family: $font-family--golos;
      border-bottom: 1px solid rgba($color-wild-blue-yonder, 0.1);

      &:hover,
      &:active,
      &.is-active {
        color: $color-medium-slate-blue;
        background-color: $color-default-white;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .custom-input__dropdown-icon {
      z-index: 7;
      right: 13px;
      width: 12px;
      height: 8px;
    }

    .custom-input__dropdown-icon-base {
      display: none;
    }

    .custom-input__dropdown-icon-alt {
      display: inline-flex;
    }

    &.custom-input--select-top {
      .custom-input__select-list {
        bottom: 42px;
        box-shadow: 0 1px 6px rgba(44, 55, 150, 0.15);
      }
    }
  }

  &--select-opened {
    input {
      z-index: 6;
      border-color: $color-night-rider;
    }

    .custom-input__select-list {
      visibility: visible;
      opacity: 1;
    }

    .custom-input__dropdown-icon {
      transform: translateY(-50%) rotate(-180deg);

      color: $color-night-rider;
    }
  }

  &--mobile-alt {
    @include viewport--md {
      padding: 0 16px;
      padding-top: 18px;
    }

    input {
      @include viewport--md {
        padding-top: 12px;
        padding-bottom: 12px;
        border: none;
      }
    }

    input:hover,
    input:focus {
      @include viewport--md {
        border: none;
      }
    }

    input:focus + label {
      @include viewport--md {
        top: -6px;
      }
    }

    .custom-input__dropdown-icon {
      @include viewport--md {
        top: 10px;
        width: 12px;
        height: 8px;
        transform: rotate(-90deg);
        color: $color-default-black;
      }
    }
  }
}

.custom-input--select-top {
  .custom-input__select-list {
    top: auto;
    bottom: 35px;
  }
}

.custom-input--select-right {
  .custom-input__select-list {
    top: -18px;
    left: 105%;
    transform: none;
  }
}

.custom-input.has-value {
  input + label {
    top: -15px;
    left: 0;
    font-size: 12px;
    line-height: 16px;

    @include viewport--md {
      font-size: 12px;
    }
  }
}

.custom-input--mobile-alt.has-value {
  input + label {
    @include viewport--md {
      top: -6px;
    }
  }
}

.custom-input--mobile-alt.custom-input--select-opened {
  .custom-input__dropdown-icon {
    @include viewport--md {
      transform: rotate(0deg);

      color: $color-default-black;
    }
  }
}
