.records-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}

.records-list__day-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.records-list__day-block--today {
  .records-list__today {
    display: block;
  }
}

.records-list__day-title {
  display: flex;
  align-items: center;
  padding: 12px 20px;

  @include viewport--md {
    padding: 12px 15px;
  }
}

.records-list__date {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-default-black;
}

.records-list__today {
  display: none;

  margin: 0;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: $color-grey;
}

.records-list__day-list {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @include viewport--md {
    padding: 0 15px;
    padding-top: 5px;
  }
}

.records-list__day-item {
  &:not(:last-child) {
    margin: 2px 0;

    @include viewport--md {
      margin: 0;
      margin-bottom: 4px;
    }
  }
}
