.time-tracker {
  display: flex;
  width: 100%;
  padding: 6px 0;
  background-color: $color-white-smoke;
  border-top: 1px solid $color-gainsboro;
  overflow-x: auto;
}

.time-tracker--week {
  display: none;

  @include viewport--md {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  .time-tracker__wrapper {
    @include viewport--md {
      width: auto;
    }
  }
}

.time-tracker--day {
  display: none;

  @include viewport--md {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }
}

.time-tracker__wrapper {
  display: flex;
  width: 100%;
}

.time-tracker__item--week {
  margin: 0 20px;
}

.time-tracker__item--day {
  margin: 0 4px;
}

.time-tracker__btn {
  @include reset-btn;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  min-height: 47px;
  padding: 5px 3px;
  border-radius: 50%;
  font-family: $font-family--base;
  background-color: $color-transparent;
  background-position: center;
  transition: background $button-transition-settings;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 4px;
    background-color: $color-transparent;
    transition: background-color $button-transition-settings;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background: rgba($color-gainsboro, 0.7);
    }
  }
}

.time-tracker__btn.today {
  &::before {
    background-color: $color-han-purple;
  }

  .time-tracker__text {
    color: $color-han-purple;
  }

  .time-tracker__number {
    color: $color-han-purple;
  }
}

.time-tracker__btn.active {
  background-color: $color-han-purple;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);

  .time-tracker__text {
    font-weight: 500;
    color: $color-default-white;
  }

  .time-tracker__number {
    color: $color-default-white;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background: rgba($color-han-purple, 0.7);
    }
  }
}


.time-tracker__text {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: $color-night-rider;
}

.time-tracker__number {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: $color-charcoal;
}
