.choose-time {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.choose-time.error {
  .choose-time__btn {
    background-color: $color-amour;
    border-color: $color-we-peep;

    svg {
      fill: $color-amour;
      stroke: $color-we-peep;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-magnolia;

        svg {
          fill: $color-magnolia;
        }
      }
    }
  }

  .choose-time__message-block {
    display: block;
  }

  .choose-time__message {
    color: $color-red-orange;
  }
}

.choose-time.warning {
  .choose-time__btn {
    background-color: $color-corn-silk;
    border-color: $color-golden-glow;

    svg {
      fill: $color-corn-silk;
      stroke: $color-golden-glow;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-magnolia;

        svg {
          fill: $color-magnolia;
        }
      }
    }
  }

  .choose-time__message-block {
    display: block;
  }

  .choose-time__message {
    color: $color-dark-orange;
  }
}

.choose-time__wrapper {
  display: flex;
  width: 100%;
}

.choose-time__btn {
  @include reset-btn;
  display: flex;
  flex-direction: column;
  height: 82px;
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 27px;
  padding-right: 27px;
  background-color: $color-default-white;
  border-top: 1px solid $color-transparent;
  border-bottom: 1px solid $color-gainsboro;
  transition: background-color $default-transition-settings;

  @media (max-width: 359px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-magnolia;

      svg {
        fill: $color-magnolia;
      }
    }
  }

  &.active {
    background-color: $color-magnolia;

    svg {
      fill: $color-magnolia;
    }
  }
}

.choose-time__btn--start {
  position: relative;
  z-index: 1;
  width: 43%;
  // max-width: 162px;
  padding-right: 15px;

  @media (max-width: 359px) {
    padding-right: 5px;
  }

  svg {
    position: absolute;
    top: -1px;
    right: -26px;
    z-index: -1;
    fill: $color-default-white;
    stroke: $color-gainsboro;
    stroke-width: 1px;
    transition:
      fill $default-transition-settings,
      stroke $default-transition-settings;

    @media (max-width: 359px) {

    }
  }
}

.choose-time__btn--end {
  width: 57%;
  // max-width: 215px;
  padding-left: 54px;

  @media (max-width: 359px) {
    padding-left: 48px;
  }
}

.choose-time__day {
  position: relative;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $color-charcoal;
  text-align: left;
}

.choose-time__time {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: $color-default-black;
  text-align: left;
}

.choose-time__message-block {
  display: none;

  width: 100%;

  background-color: $color-default-white;
  border-bottom: 1px;
}

.choose-time__message-wrap {
  width: 100%;
  margin: 0;
  padding: 8px 16px;
  padding-bottom: 11px;
  border-bottom: 1px solid $color-gainsboro;
}

.choose-time__message {
  width: 100%;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.choose-time__value {
  display: none;

  &.active {
    display: block;
  }
}

.choose-time {
  .data-block--day-time {
    padding-top: 16px;
    padding-bottom: 11px;
  }

  .day-time__label {
    font-size: 16px;
    top: -4px;
  }
}
