.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  margin-left: 33.333333%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba($color-lucky-point, 0.9);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  transition: opacity $default-transition-settings, transform $default-transition-settings, visibility $default-transition-settings;
  pointer-events: none;

  @include viewport--sm {
    margin-left: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 24px 40px;

    @include viewport--md {
      padding: 24px 16px;
    }

    @include viewport--sm {
      padding: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__content {
    position: relative;
    width: 480px;
    border-radius: 20px;
    background-color: $color-default-white;
    // overflow: hidden;

    @include viewport--sm {
      width: 100%;
      min-height: 100vh;
      min-height: calc(100 * var(--vh, 1vh));
      height: 100%;
      border-radius: 0;
      overflow: visible;
    }
  }

  &__close-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 24px;
    right: 24px;
    padding: 0;
    background: $color-default-white;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(20px);
    border: 2px solid $color-lucky-point;
    border-radius: 50%;

    @include viewport--md {
      display: none;
    }

    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 1;
    }

    & svg {
      width: 16px;
      height: 100%;
      color: $color-lucky-point;
    }
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &--review {
    .modal__close-btn {
      @include viewport--sm {
        display: block;
        right: 16px;
        top: 16px;
      }
    }
  }
}
