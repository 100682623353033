.btn-time {
  @include reset-btn();
  position: relative;
  width: 100%;
  padding: 18px 16px 7px;
  transition: all $default-transition-settings;
  min-height: 64px;
}

.btn-time__caption {
  position: absolute;
  top: 23px;
  left: 16px;
  color: rgba(17,17,17,.48);
}

.btn-time__icon {
  position: absolute;
  top: 23px;
  right: 20px;
}

.btn-time.active {
  .btn-time__caption {
    position: absolute;
    top: 7px;
    left: 16px;
    font-size: 13px;
    line-height: 20px;
  }

  .btn-time__table {
    display: flex;
    flex-direction: column;
    padding-top: 18px;
    letter-spacing: 0.15px;
  }

  .btn-time__row {
    display: flex;
    margin-bottom: 6px;
  }

  .btn-time__day {
    vertical-align: top;
    width: 32px;
    padding-right: 8px;
    color: rgba(17,17,17,.48);
  }

  .btn-time__time {
    display: flex;
    flex-direction: column;
    color: $color-default-black;
  }


  .btn-time__time-item {
    padding-bottom: 8px;
  }
}
