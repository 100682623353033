.share {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    margin: 0;
    margin-bottom: 10px;
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $color-wild-blue-yonder;
  }

  .profile-social-list {
    &__item {
      margin-right: 8px;
    }
  }
}
