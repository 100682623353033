.booking-group {
  margin: 0;
  padding: 0 16px;
  list-style: none;

  @media (max-width: $viewport--sm) {
    padding: 0 8px;
  }

  &__item {
    margin-bottom: 20px;
  }
}
