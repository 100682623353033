.page {
  padding-top: 53px;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  max-height: 100vh;
  max-height: calc(100 * var(--vh, 1vh));
  display: flex;
  flex-grow: 1;

  &--no-menu-sidebar {
    padding-top: 0;
    height: auto;
    max-height: none;
  }

  @include viewport--sm {
    padding-top: 0;
  }
}
