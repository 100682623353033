.group-item {
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    height: 1px;
    width: calc(100% - 28px);
    background-color: rgba($color-default-white, 0.45);
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__day {
    margin: 0 10px;
    margin-right: 14px;
    font-family: $font-family--golos;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba($color-default-white, 0.45);
  }

  &__date {
    margin: 0;
    margin-right: 4px;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 20px;
    color: $color-default-white;

    &::after {
      content: "•";
      margin-left: 4px;
      color: rgba($color-default-white, 0.45);
    }
  }

  &__time {
    margin: 0;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 20px;
    color: $color-default-white;

    span {
      color: rgba($color-default-white, 0.45);
    }
  }

  &__vacancy {
    display: flex;
    align-items: center;
    color: rgba($color-default-white, 0.45);
  }

  &__quantity {
    flex-shrink: 0;
    margin: 0;
    margin-left: 4px;
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 20px;
    color: rgba($color-default-white, 0.45);
  }
}
