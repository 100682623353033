.button-with-icon {
  @include reset-btn;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  outline: none;
  background-color: transparent;

  &:disabled {
    cursor: auto;

    svg {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  svg {
    transition: color $default-transition-settings;
  }
}

.button-with-icon--fill {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        color: $color-han-purple;
      }
    }
  }
}

.button-with-icon--black {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: transparent;
    }
  }

  svg {
    color: $color-default-black;
  }
}

.button-with-icon--nobel {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: transparent;
    }
  }

  svg {
    color: $color-nobel;
  }
}

.button-with-icon--charcoal {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: transparent;
    }
  }

  svg {
    color: $color-charcoal;
  }
}

.button-with-icon--grey {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: transparent;
    }
  }

  svg {
    color: $color-grey;
  }
}
