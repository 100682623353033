.radio-rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  label {
    display: inline-block;
    cursor: pointer;
    font-size: 30px;
    margin-right: 3px;

    svg {
      color: $color-hawkes-blue;
      transition: color $default-transition-settings;
    }
  }

  label:hover svg,
  label:hover ~ label svg,
  input:checked ~ label svg {
    color: $color-orange;
  }
}
