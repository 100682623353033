.select-btn {
  @include reset-btn;
  position: relative;
  padding-right: 50px;
  width: 100%;
  display: block;
  text-align: left;
}

.select-btn__ic {
  position: absolute;
  color: $color-default-black;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}

.select-btn__field {
  position: relative;
  width: 100%;
  display: block;
}

.select-btn__label {
  font-weight: 400;
  color: rgba($color-nero-2, 0.48);
  position: absolute;
  display: block;

  top: 4px;
  left: 16px;
  font-size: 16px;
  line-height: 24px;

  .select-btn--has-value & {
    top: 5px;
    left: 15px;
    font-size: 13px;
    line-height: 20px;
  }
}

.select-btn__input {
  width: 100%;
  padding: 26px 16px 6px;
  padding-right: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color-nero-2;
  border-radius: 4px;
  display: block;
}

.select-btn__input-ic {
  color: $color-han-purple;
  position: relative;
  top: 2px;
  margin-left: 4px;
}
