@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-v20-latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2"),
    url("../fonts/roboto-v20-latin_cyrillic-ext_cyrillic-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-v20-latin_cyrillic-ext_cyrillic-500.woff2") format("woff2"),
    url("../fonts/roboto-v20-latin_cyrillic-ext_cyrillic-500.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-v15-latin_cyrillic-ext_cyrillic-700.woff2") format("woff2"),
    url("../fonts/montserrat-v15-latin_cyrillic-ext_cyrillic-700.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Golos";

  font-display: swap;
  src:
    url("../fonts/golos-regular.woff2") format("woff2"),
    url("../fonts/golos-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Golos";

  font-display: swap;
  src:
    url("../fonts/golos-medium.woff2") format("woff2"),
    url("../fonts/golos-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Golos";

  font-display: swap;
  src:
    url("../fonts/golos-demibold.woff2") format("woff2"),
    url("../fonts/golos-demibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Golos";

  font-display: swap;
  src:
    url("../fonts/golos-bold.woff2") format("woff2"),
    url("../fonts/golos-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Rouble";

  font-display: swap;
  src:
    url("../fonts/rouble.woff2") format("woff2"),
    url("../fonts/rouble.woff") format("woff");
}
