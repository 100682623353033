.calendar-slider {
  &__title {
    margin: 0;
    text-align: center;
    font-family: $font-family--golos;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-default-white;
  }

  &__nav {
    position: relative;
  }

  .slider-btn {
    top: -4px;
    width: 30px;
    height: 30px;
    background-color: $color-default-white;
    border: 2px solid $color-default-white;

    @include viewport--sm {
      display: flex;
    }

    &--prev {
      left: 16px;
    }

    &--next {
      right: 16px;
    }

    &:disabled {
      background-color: rgba($color-default-white, 0.2);
      color: rgba($color-default-white, 0.15);
      border-color: rgba($color-default-white, 0.02);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        &:disabled {
          border-color: $color-transparent;
          color: rgba($color-default-white, 0.15);
        }
      }
    }
  }

  &__slide {
    display: none;

    &.active {
      display: block;
    }
  }

  .calendar-list {
    margin: 27px 0;
    margin-top: 24px;
  }
}
