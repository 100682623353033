.field-search {
  display: flex;
  align-items: center;

  @include viewport--md {
    justify-content: flex-end;
    background-color: transparent;
  }
}

.field-search.active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  padding: 0 15px;

  @include viewport--md {
    background-color: $color-default-white;
  }

  @include viewport--xsm {  //уменьшен ради позиционирования в "записях"
    height: 56px;
  }

  .field-search__btn-close {
    display: inline-flex;
  }

  .field-search__input {
    width: 100%;
    margin-left: 5px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    transition:
      width $default-transition-settings,
      opacity $default-transition-settings,
      visibility $default-transition-settings,
      margin-left $default-transition-settings;
  }
}

.field-search__btn-open {
  display: none;

  @include viewport--md {
    display: block;
  }
}

.field-search__btn-close {
  display: none;
  align-items: center;
  justify-content: center;
}

.field-search__input {
  width: 100%;

  @include viewport--md {
    width: 0;
    margin-left: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;


    transition:
      width 0.3s cubic-bezier(0.22, 1, 0.36, 1),
      opacity 0.3s cubic-bezier(0.22, 1, 0.36, 1),
      visibility 0.3s cubic-bezier(0.22, 1, 0.36, 1),
      margin-left 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }


  .field-text--border input {
    @include viewport--md {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
