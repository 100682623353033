.appointment {
  position: relative;
  padding: 7px 9px;
  background: $color-alice-blue-2;
  border: 1px solid rgba($color-wild-blue-yonder, 0.2);
  border-radius: 10px;

  &__close {
    position: absolute;
    right: 12px;
    top: 7px;
    color: $color-red-orange;
    padding: 0;
    background-color: $color-transparent;
    border: none;
    cursor: pointer;
    transition: opacity $default-transition-settings;

    &:hover,
    &:active {
      opacity: 0.6;
      transition: opacity $default-transition-settings;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 3px;
  }

  &__time {
    display: flex;
    align-items: center;
  }

  &__info {
    margin: 0;
    padding-left: 18px;
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 16px;
    color: $color-wild-blue-yonder;
  }

  &__day {
    margin: 0;
    position: relative;
    margin-right: 2px;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 20px;
    color: $color-lucky-point;

    &::after {
      margin-left: 2px;
      content: "•";
    }
  }

  &__interval {
    margin: 0;
    font-family: $font-family--golos;
    font-size: 14px;
    line-height: 20px;
    color: $color-lucky-point;

    span {
      color: $color-wild-blue-yonder;
    }
  }
}
