.appointment-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  overflow-y: auto;

  @include viewport--sm {
    margin: 0 -16px;
    padding-left: 16px;
    padding-right: 8px;
  }

  &__item {
    min-width: 280px;
    flex-shrink: 0;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
      padding-right: 16px;
      min-width: 296px;
    }
  }

  &::-webkit-scrollbar {
    appearance: none;
    height: 12px;
    border-radius: 20px;

    @include viewport--sm {
      display: none;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: rgba($color-wild-blue-yonder, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid $color-lavender;
    background-color: $color-wild-blue-yonder;
  }

  &--one {
    .appointment-list__item {
      width: 100%;
      margin: 0;
    }
  }
}
