.button-with-popup {
  position: relative;
  display: inline-flex;
}

.button-with-popup.active {
  .button-with-popup__popup {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.button-with-popup__btn {
  @include reset-btn;

  position: relative;
  display: inline-flex;
  align-items: center;
  color: $color-default-white;
  transition: opacity $default-transition-settings;

  svg {
    margin-left: 12px;
    color: $color-default-white;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

.button-with-popup__popup {
  position: absolute;
  top: 28px;
  right: -5px;
  display: flex;
  flex-direction: column;
  min-width: 245px;
  padding: 16px;
  padding-bottom: 20px;
  background-color: $color-default-white;
  box-shadow:
    0 4px 4px rgba(51, 51, 51, 0.04),
    0 14px 24px rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    opacity $default-transition-settings,
    visibility $default-transition-settings;
}

.button-with-popup__popup-btn {
  @include reset-btn;

  display: inline-flex;

  font-family: $font-family--base;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color-nero-2;
  transition: opacity $default-transition-settings;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
