.tag-list-admin {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: -2px;
  width: 100%;
}

.tag-list-admin__item {
  margin: 2px;
}
