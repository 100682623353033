.flatpickr-calendar {
  border: 1px solid $color-whisper;
  box-shadow: 2px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 4px;

  &::before,
  &::after {
    content: none;
  }
}

.flatpickr-current-month span.cur-month:hover {
  background: none;
}

.flatpickr-current-month span.cur-month {
  font-family: $font-family--base;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  color: $color-default-black;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-family: $font-family--base;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  color: $color-default-black;
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
  background-color: rgba($color-default-black, 0.05);
}

span.flatpickr-weekday {
  font-family: $font-family--base;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: $color-charcoal;
}

.flatpickr-day {
  max-width: 40px;
  width: 40px;
  height: 40px;
  font-family: $font-family--base;
  font-weight: 400;
  font-size: 12px;
  line-height: 40px;
  color: $color-charcoal;
}

.flatpickr-day.today {
  color: $color-han-purple;
  border-color: $color-han-purple;
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background-color: $color-magnolia;
  border-color: $color-magnolia;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange.nextMonthDay:focus,
.flatpickr-day.endRange.nextMonthDay:hover,
.flatpickr-day.endRange.prevMonthDay:focus,
.flatpickr-day.endRange.prevMonthDay:hover,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange.nextMonthDay:focus,
.flatpickr-day.startRange.nextMonthDay:hover,
.flatpickr-day.startRange.prevMonthDay:focus,
.flatpickr-day.startRange.prevMonthDay:hover,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  color: $color-default-white;
  border-color: $color-han-purple;
  background-color: $color-han-purple;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);
}

//визуально скрывает дни пред./след. месяца

// .flatpickr-day.nextMonthDay,
// .flatpickr-day.notAllowed,
// .flatpickr-day.notAllowed.nextMonthDay,
// .flatpickr-day.notAllowed.prevMonthDay,
// .flatpickr-day.prevMonthDay {
//   color: $color-default-white;
// }

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 50%;
  transition: background-color $default-transition-settings;
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 5px;//60px;
  top: 3px;
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 5px;//60px;
  top: 3px;
}

.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path {
  fill: $color-default-black;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: $color-han-purple;
  background: $color-han-purple;
  color: $color-default-white;
}

.flatpickr-day.today.inRange:hover,
.flatpickr-day.today.inRange:focus {
  color: $color-han-purple;
  border-color: $color-transparent;
  background: $color-magnolia;
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  position: relative;
  border-radius: 50%;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -20px;
    z-index: -1;
    width: 100%;
    height: calc(100% + 2px);
    background-color: $color-magnolia;
  }
}

.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
  position: relative;
  border-radius: 50%;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 20px;
    z-index: -1;
    width: 100%;
    height: calc(100% + 2px);
    background-color: $color-magnolia;
  }
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
  // box-shadow: -10px 0 0 $color-magnolia;
  box-shadow: none;
}

.flatpickr-day.inRange {
  border-radius: 0;
  // box-shadow: -5px 0 0 $color-magnolia, 5px 0 0 $color-magnolia;
  box-shadow: 5px 0 0 $color-magnolia;
}
