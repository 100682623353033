.calendar-schedule-week {
  position: relative;
  display: flex;

  height: 100%;
  max-height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  @include viewport--sm {
    width: 100%;
  }
}

.calendar-schedule-week__hours {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 60px;
  padding-right: 13px;
  padding-top: 38px;
  padding-bottom: 20px;
  background-color: $color-default-white;

  @include viewport--md {
    padding-right: 10px;
    padding-top: 32px;
  }

  @include viewport--sm {
    display: none;
  }
}

.calendar-schedule-week__time {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: $color-charcoal;

  &:not(:last-child) {
    margin-bottom: 26px;

    @include viewport--md {
      margin-bottom: 20px;
    }
  }
}

.calendar-schedule-week__days {
  display: none;

  @include viewport--sm {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 63px;

    padding-right: 13px;
    padding-top: 30px;
    padding-bottom: 20px;
    // border-right: 1px solid $color-gainsboro;
    background-color: $color-default-white;
  }
}

.calendar-schedule-week__day {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;

  @include viewport--sm {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -16px;
      transform: translateY(-50%);
      width: 4px;
      height: 30px;
      background-color: $color-default-white;
      transition: background-color $default-transition-settings;
    }
  }

  &:not(:last-child) {
    @include viewport--sm {
      margin-bottom: 68px;
    }
  }
}

.calendar-schedule-week__day--today {
  @include viewport--sm {
    &::before {
      background-color: $color-han-purple;
    }
  }

  .calendar-schedule-week__day-number {
    @include viewport--sm {
      color: $color-han-purple;
    }
  }

  .calendar-schedule-week__day-name {
    @include viewport--sm {
      color: $color-han-purple;
    }
  }
}

.calendar-schedule-week__day-number {
  color: $color-charcoal;
}

.calendar-schedule-week__day-name {
  margin-left: 10px;
  color: $color-grey;
}

.calendar-schedule-week__table {
  display: flex;
  width: calc(100% - 60px);

  @include viewport--sm {
    flex-direction: column;
    width: calc(100vw - 63px);
    // overflow-x: auto;
  }
}

.calendar-schedule-week__table-col {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% / 7);
  background-color: $color-default-white;

  @include viewport--sm {
    flex-direction: row;
    width: auto;
    min-height: 88px;
    border-bottom: 1px solid $color-gainsboro;
    border-left: 1px solid $color-gainsboro;
    overflow-x: auto;
  }
}

.calendar-schedule-week__table-row {
  position: relative;
  display: flex;
  min-height: 25px;
  border-left: 1px solid $color-gainsboro;
  border-bottom: 1px solid $color-transparent;
  background-image: linear-gradient(135deg, $color-whisper 25%, $color-default-white 25%, $color-default-white 50%, $color-whisper 50%, $color-whisper 75%, $color-default-white 75%, $color-default-white 100%);
  background-size: 16px 16px;

  &:nth-child(2n) {
    border-bottom: 1px solid $color-gainsboro;
    background-position: top 0 left 7px;

    @include viewport--md {
      background-position: top 0 left 10px;
    }

    @include viewport--sm {
      border-bottom: none;
    }
  }

  @include viewport--md {
    min-height: 22px;
  }

  @include viewport--sm {
    display: none;
  }
}

.calendar-schedule-week__table-row--working {
  background-image: none;
  background-color: $color-default-white;
}


.calendar-schedule-week__current-time.current-time {
  @include viewport--sm {
    display: none;
  }
}
