.list-pagination {
  border-top: 1px solid $border-color;
  background: $color-white-smoke;

  padding: 6px 10px 6px 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 359px) {
    padding-left: 10px;
    padding-right: 6px;

    justify-content: space-between;
  }
}

.list-pagination__view-filter {
  display: flex;
  align-items: center;

  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $color-mid-grey;

  margin-right: 68px;

  @include viewport--sm {
    margin-right: 35px;
  }

  @media (max-width: 359px) {
    font-size: 14px;
    margin-right: 15px;
  }
}

.list-pagination__view-filter .custom-input--select {
  margin-left: 3px;
  width: 35px;

  .custom-input__select-list {
    width: 100%;
  }

  input {
    background: transparent;
    border-color: transparent;
  }

  .custom-input__select-list {
    width: 45px;
    left: -5px;
  }

  .custom-input__select-item {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }
}

.list-pagination__rows-number {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #212121;
  margin-right: 21px;

  @include viewport--sm {
    margin-right: 14px;
  }

  @media (max-width: 359px) {
    margin-right: 8px;
  }
}

.list-pagination__btns {
  display: flex;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.list-pagination__view {
  margin-right: 20px;
}
