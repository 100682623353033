.review-card {
  padding: 32px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid $color-gainsboro;
  background-color: $color-default-white;
  color: $color-default-black;

  @include viewport--sm {
    padding: 22px 20px 18px;
  }

}

.review-card__header {
  margin-bottom: 15px;
}

.review-card__author-date-wrapper {
  display: flex;
  justify-content: space-between;

  @include viewport--xsm {
    margin-bottom: 4px;
  }

  @include viewport--xxsm {
    flex-wrap: wrap;
  }
}

.review-card__title {
  margin: 0;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-right: 10px;

  // @include viewport--xxsm {
  //   width: 50%;
  // }
}

.review-card__date-tag-wrapper {
  display: flex;

  @include viewport--xxsm {
    // width: 40%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.review-card__date {
  color: $color-nobel;
  font-size: 14px;
  letter-spacing: 0.15px;
  margin-left: 8px;
}

.review-card__text {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  margin-bottom: 25px;

  p {
    margin: 0;
    margin-bottom: 10px;
  }
}

.review-card__controls form {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.review-card__toggle {
  max-width: 140px;
  margin-right: 32px;

  @include viewport--xxsm {
    margin-bottom: 15px;
  }
}

.review-card__toggle .field-switch__label {
  color: $color-default-black;
  font-size: 13px;
  line-height: 20px;
  margin-right: 5px;
}

.review-card__grading {
  display: flex;
  // align-items: baseline;
  font-size: 13px;
  line-height: 18px;

  label {
    margin-right: 12px;
    padding-top: 3px;
  }

  input {
    text-align: center;
    border-radius: 6px;
    border: 1px solid $color-gainsboro;
    opacity: 1;
    width: 32px;
    padding-top: 2px;
    padding-bottom: 3px;
    font-size: 13px;
    line-height: normal;
    transition: all $default-transition-settings;

    &:hover,
    &:focus {
      outline: none;
      border-color: $color-han-purple;
    }
  }
}
