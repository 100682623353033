.plan-panel {
  height: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  transition:
    opacity $default-transition-settings,
    visibility $default-transition-settings,
    height $default-transition-settings;
}

.plan-panel.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  height: auto;
  overflow: visible;
}

.plan-panel__header {
  padding: 0 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-gainsboro;
}

.plan-panel__desc {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-nero;

  @include viewport--sm {
    max-width: 343px;
    margin-left: auto;
    margin-right: auto;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.plan-panel__date {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-default-black;

  @include viewport--sm {
    max-width: 343px;
    margin-left: auto;
    margin-right: auto;
  }
}

.plan-panel__checkbox {
  margin-right: 18px;
  margin-top: 5px;

  @media (max-width: 374px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .field-checkbox__label {
    padding-left: 5px;
    white-space: nowrap;
  }
}

.plan-panel__time {
  margin-right: 10px;

  @media (max-width: 374px) {
    margin-right: 5px;
  }
}

.plan-panel__time-row {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.plan-panel__time-start {
  position: relative;
  width: 64px;
  margin-right: 16px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -13px;
    transform: translateY(-50%);
    width: 10px;
    height: 2px;
    background-color: $color-charcoal;
  }
}

.plan-panel__time-end {
  width: 64px;
  margin-right: 6px;
}

.plan-panel__btn-copy {
  margin-right: 3px;
  margin-left: auto;
}

.plan-panel__time-note {
  width: 178px;
  margin: 0;
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $color-nobel;
}
