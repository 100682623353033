.calendar-item {
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;
  }

  &__title {
    margin: 0;
    font-family: $font-family--golos;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba($color-default-white, 0.45);
  }

  &__today  {
    margin: 0;
    padding: 2px 8px;
    font-family: $font-family--golos;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $color-default-white;
    border-radius: 5px;
    background-color: $color-purple-heart;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 4px);
  }

  &__slot {
    margin-right: 4px;
    margin-bottom: 1px;
    width: 71px;

    @include viewport--sm {
      width: calc(16.666666% - 4px);
    }
  }

  &__time {
    @include reset-btn;
    width: 100%;
    padding: 0px 16px;
    border-radius: 4px;
    background-color: $color-default-white;
    font-family: $font-family--golos;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $color-lucky-point;
    border: 1px solid $color-default-white;
    transition: color $default-transition-settings, background-color $default-transition-settings, border-color $default-transition-settings;

    @include viewport--sm {
      padding: 1px 0;
    }

    &.is-unavailable {
      border: 1px solid rgba($color-default-white, 0.15);
      background-color: $color-transparent;
      color: rgba($color-default-white, 0.45);
      cursor: unset;
      pointer-events: none;
    }

    &:focus {
      border-color: $color-dodger-blue;
      background-color: $color-dodger-blue;
      color: $color-default-white;
      transition: color $default-transition-settings, background-color $default-transition-settings, border-color $default-transition-settings;
    }

    &.is-active {
      border-color: $color-dodger-blue;
      background-color: $color-dodger-blue;
      color: $color-default-white;
    }
  }
}
