.title {
  margin: 0;
  font-family: $font-family--golos;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: $color-lucky-point;

  @include viewport--sm {
    font-size: 22px;
    line-height: 26px;
  }

  &--small {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}
