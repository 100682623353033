.active-status {
  display: inline-block;
  padding: 6px 12px 5px;
  text-transform: uppercase;
  border-radius: 100px;
  background: $color-white-smoke-2;

  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: $color-matterhorn;
  text-align: center;

  &--active {
    paddin: 5px 12px;
    background: $color-han-purple;
    color: $color-default-white;
  }
}
