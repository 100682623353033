.panel-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .15px;
  color: $color-default-black;
}
