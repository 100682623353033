.tag {
  display: inline-block;
  padding: 1px 6px 3px;
  background: rgba($color-han-purple, 0.1);
  border-radius: 4px;

  &__text {
    margin: 0;
    font-family: $font-family--golos;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $color-medium-slate-blue;
  }
}
