.data-block {
  min-height: 64px;
  border-bottom: 1px solid $border-color;

  display: flex;
  align-items: center;
}
.data-block--border-top {
  border-top: 1px solid #e0e0e0;
}
.data-block-with-error {
  min-height: 96px;
}
.data-block-with-error .field-text.error .field-text__error-message {
  position: relative;
  bottom: 0;
  padding-left: 16px;
}
.field-text.error input {
  border-radius: 0;
}

.data-block--no-border {
  border: none;
}

.data-block--text-field .field-text {
  width: 100%;
  padding-right: 0;

  input,
  textarea {
    box-shadow: none;
    padding-right: 32px;
  }
}

.data-block--ckeck-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 16px;

  > * {
    margin-bottom: 15px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.data-block--link {
  padding: 12px 8px;
}

.data-block--delete {
  padding: 12px 8px;
}

.data-block--contacts {
  padding: 0 16px;
}

.data-block--adress {
  padding: 12px 16px 20px;
}

.data-block--select {
  transform: translateZ(0);

  .select-btn {
    min-height: 64px;
  }
  &.error {
   + .choose-time__message-block {
      display: block;
      color: $color-red-orange;
    }
  }
}

.data-block--comment {
  border-color: $color-alice-blue;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    height: 1px;
    background: $color-alice-blue;
  }
}

.data-block--day-time {
  padding: 0 9px;
}

.data-block--period {
  align-items: flex-start;
  padding: 16px;

  @include viewport--sm {
    justify-content: center;
  }

  @media (max-width: 374px) {
    flex-wrap: wrap;
  }
}

.data-block--choose-time {
  border: none;
}

.data-block--switch-field {
  padding: 12px 16px;
  overflow: hidden;
  transform: translateZ(0);
}

.data-block--data-item {
  padding: 11px 8px 12px 16px;
}

.data-block--duration {
  padding-top: 16px;
  padding-bottom: 11px;

  .day-time__label {
    top: -4px;
    font-size: 16px;
  }
}

.data-block--add-tag {
  padding-right: 15px;
  border-bottom: none;

  .btn-with-icon {
    color: $color-default-black;
  }
}

.data-block--tags {
  padding: 8px 10px 8px 16px;
  background-color: #fafafa;
}
