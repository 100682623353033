.full-review {
  padding-top: 56px;
  
  .user-review {
    box-shadow: none;
  }

  .user-review__text {
    &::after {
      display: none;
    }
  }
}
