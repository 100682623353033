.slider-btn {
  padding: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -48px;
  background-color: $color-transparent;
  border: 2px solid $color-transparent;
  color: $color-wild-blue-yonder;
  cursor: pointer;
  transition: border-color $default-transition-settings, color $default-transition-settings;

  &:disabled {
    opacity: 0.3;
    cursor: unset;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-lucky-point;
      color: $color-lucky-point;
      transition: border-color $default-transition-settings, color $default-transition-settings;

      &:disabled {
        border-color: $color-default-white;
        color: $color-wild-blue-yonder;
      }
    }
  }

  @include viewport--sm {
    display: none;
  }
}
