.comment {
  background: $color-alice-blue;
  padding: 16px 16px 17px 47px;
  position: relative;
  flex: 0 0 100%;
}

.comment__ic {
  color: $color-han-purple;
  position: absolute;
  left: 16px;
  top: 20px;
}

.comment__content {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-default-black;
}

.comment.collapsed .comment__content {
  /* stylelint-disable */
  display: -webkit-box;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* stylelint-enable */

  -webkit-line-clamp: (1);
  max-height: 24px;
}

.comment__toggle {
  padding: 4px;
  margin-left: -4px;
  font-size: 14px;
  line-height: 20px;

  .open {
    display: none;
  }
}

.comment.collapsed .comment__toggle {
  .open {
    display: block;
  }

  .close {
    display: none;
  }
}
