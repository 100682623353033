.profile-reviews {
  @include viewport--sm {
    padding-top: 16px;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    padding-right: 100px;
    font-family: $font-family--golos;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    color: $color-lucky-point;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    color: $color-orange;
  }

  &__slider {
    position: relative;
    margin-top: 13px;
    padding-bottom: 14px;
  }

  &__wrapper {
     @include viewport--sm {
      display: flex;
    }
  }

  &__slide {
    display: none;

    @include viewport--sm {
      display: block;
      flex-shrink: 0;
      max-width: 100%;
      margin-right: 8px;
    }

    @include viewport--xxsm {
      max-width: 280px;
    }

    &.active {
      display: block;
    }
  }

  .slider-btn--prev {
    right: 48px;
  }

  .slider-btn--next {
    right: 0;
  }
}
