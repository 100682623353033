.panel-record {
  @include reset-btn;

  position: absolute;
  left: 1px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2px);
  max-width: 100%;
  min-height: 47px;
  margin: 1px;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 13px;
  padding-right: 8px;
  font-family: $font-family--base;
  border-radius: 4px;
  outline: none;
  overflow: hidden;
  transition: background-color $default-transition-settings;

  @include viewport--md {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  @include viewport--sm {
    flex-direction: column;
    padding-top: 6px;
    padding-bottom: 9px;
    padding-right: 18px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    border-radius: 4px 0 0 4px;
  }
}

.panel-record--week {
  @include viewport--sm {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    flex-shrink: 0;
    width: 96px;
    height: 86px;
    padding-left: 12px;
    padding-right: 12px;
    min-height: 47px!important;
  }
}

.panel-record--day {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 21px);
  min-height: 39px;
  padding-top: 12px;

  .panel-record__info {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    max-width: 80%;
    margin-bottom: 0;
  }

  .panel-record__client-info {
    order: 2;
    width: auto;
    max-width: 75%;
    margin-bottom: 0;
  }

  .panel-record__client-name {
    max-width: none;
  }

  .panel-record__client-time {
    order: 1;
    max-width: 70px;
    margin-right: 15px;
  }

  .panel-record__comment {
    margin-top: 0;
    margin-left: 16px;

    @include viewport--sm {
      margin-left: auto;
    }
  }
}

.panel-record--list {
  position: relative;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
  margin: 0;
  padding-top: 12px;

  @include viewport--md {
    flex-direction: row;
    width: 100%;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 9px;
  }

  .panel-record__info {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 180px;
    margin-right: 12px;

    @include viewport--md {
      width: 168px;
      margin-bottom: 0;
    }

    @media (max-width: 359px) {
      width: 132px;
    }
  }

  .panel-record__client-info {
    position: relative;
    order: 2;
    width: auto;
    max-width: 60%;
    margin-bottom: 0;
    padding-right: 18px;

    @include viewport--sm {
      max-width: 48%;
    }
  }

  .panel-record__client-name {
    max-width: none;
  }

  .panel-record__client-time {
    order: 1;
    max-width: 70px;
    margin-right: 15px;

    @include viewport--md {
      margin-right: 10px;
    }
  }

  .panel-record__star {
    position: absolute;
    top: 0;
    right: 0;
  }

  .panel-record__place {
    width: 140px;
    margin-left: 0;
    margin-right: 12px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .panel-record__comment {
    margin-top: 0;
    margin-left: 16px;

    @include viewport--sm {
      margin-left: auto;
    }
  }

  .panel-record__status {
    @include viewport--md {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

.panel-record--not-paid {
  background-color: $color-whisper;

  &::before {
    background-color: $color-nobel;
  }

  .panel-record__star {
    color: $color-han-purple;
  }

  .panel-record__comment {
    color: $color-han-purple;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-whisper, 0.7);
    }
  }
}

.panel-record--paid {
  background-color: $color-magnolia;

  &::before {
    background-color: $color-han-purple;
  }

  .panel-record__star {
    color: $color-han-purple;
  }

  .panel-record__comment {
    color: $color-han-purple;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-magnolia, 0.7);
    }
  }
}

.panel-record--regular {
  .panel-record__star {
    display: inline-flex;
  }
}

.panel-record--comment {
  .panel-record__comment {
    display: inline-flex;
  }
}

.panel-record__info {
  display: flex;
  flex-direction: column;
  width: 86%;

  @include viewport--sm {
    width: 100%;
    margin-bottom: 30px;
  }
}

.panel-record__client-info {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
}

.panel-record__client-name {
  max-width: 86%;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $color-default-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-record__star {
  display: none;
  width: 13px;
  margin-left: 5px;
}

.panel-record__client-time {
  display: flex;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.015em;
}

.panel-record__client-time-start {
  position: relative;
  margin-right: 8px;

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    right: -6px;
    width: 4px;
    height: 1px;
    background-color: $color-default-black;
  }
}

.panel-record__comment {
  width: 11px;
  display: none;
  margin-top: 2px;
  margin-left: 5px;

  @include viewport--sm {
    margin: 0;
  }
}

.panel-record__place {
  margin-left: 24px;
  margin-right: 42px;
  font-size: 12px;
  line-height: 16px;

  @include viewport--md {
    display: none;
  }
}

.panel-record__price {
  margin-right: 46px;
  font-size: 12px;
  line-height: 16px;
  min-width: 80px;

  @include viewport--md {
    display: none;
  }
}

.panel-record__status {
  margin-right: 26px;

  @include viewport--sm {
    margin-right: auto;
    margin-left: 47px;
  }
}
