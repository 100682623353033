.calendar-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 48px;
  padding: 5px 20px;
  border-bottom: 1px solid $color-gainsboro;
  background-color: $color-default-white;

  @include viewport--md {
    // display: none;
    // justify-content: flex-start;
    // padding: 8px 15px;
    // border-bottom: none;
    top: -55px;
    width: auto;
    border-bottom: none;
    background-color: $color-transparent;
    pointer-events: none;
  }

  @include viewport--sm {
    // display: flex;
    //width: 100%;
    //width: 375px;
    top: 0;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid $color-gainsboro;
    background-color: $color-default-white;
    pointer-events: auto;
  }
}

.calendar-header__week-period {
  width: 204px;

  @include viewport--lg {
    //width: 2%;
  }

  @include viewport--md {
    display: none;
    // margin-right: 24px;
  }

  @include viewport--sm {
    display: inline-flex;
    max-width: 204px;
    width: auto;
    margin-right: 0;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $color-nero;
    white-space: nowrap;
  }
}

.calendar-header__control-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  max-width: 160px;

  @include viewport--lg {
    width: 25%;
  }

  @include viewport--md {
    display: none;
  }
}

.calendar-header__control-item {
  &:not(:last-child) {
    margin-right: 10px;
  }
  .button {
    white-space: nowrap;
  }
}

.calendar-header__btn-box {
  width: 204px;
  margin-left: 15px;

  @include viewport--md {
    max-width: 185px;
    // margin-left: 352px;//44%;
    margin-left: 450px;
    pointer-events: auto;
  }

  @include viewport--sm {
    max-width: 185px;
    width: 62%;
    margin-left: 10px;
  }
}

.calendar-header__btn {
  padding: 5px 10px;

  @media (max-width: 359px) {
    padding: 5px;
  }
}
