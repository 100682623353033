@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin viewport--xl {
  @media (max-width: $viewport--xxl - 1) {
    @content;
  }
}

@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

@mixin viewport--xsm {
  @media (max-width: $viewport--600 - 1) {
    @content;
  }
}

@mixin viewport--xxsm {
  @media (max-width: $viewport--375 - 1) {
    @content;
  }
}

@mixin reset-btn {
  padding: 0;
  margin: 0;
  background: none;
  color: inherit;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
}

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
