.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 8px 10px;
  font-family: $font-family--base;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.04),
    0 2.75px 9px rgba(0, 0, 0, 0.2);
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 6px;
  appearance: none;
  cursor: pointer;

  // &:disabled {
  //   color: rgba(0, 0, 0, 0.26);
  //   background-color: rgba(0, 0, 0, 0.12);
  //   cursor: auto;
  // }

  &:disabled {
    color: $color-default-white;
    background-color: $color-silver;
    pointer-events: none;
  }
}

.button--alt {
  padding: 10px 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  letter-spacing: 0;
}

.button--small {
  width: auto;
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  letter-spacing: 0;
  box-shadow: none;
}

.button--small.active {
  color: $color-default-white;
  background-color: $color-han-purple;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      // background: rgba($color-han-purple, 0.7);
      background-color: $color-light-slate-blue;
    }

    &:disabled:hover {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button--uppercase {
  text-transform: uppercase;
}

.button--border {
  width: auto;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $color-charcoal;
  background-color: $color-default-white;
  border-radius: 4px;
  border: 1px solid $color-gainsboro;
  letter-spacing: 0;
  box-shadow: none;
}

.button--white {
  color: $color-charcoal;
  background-color: $color-default-white;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button--purple {
  color: $color-default-white;
  background-color: $color-han-purple;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button--magnolia {
  color: $color-han-purple;
  background-color: $color-magnolia;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button--slate {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.02em;
  font-family: $font-family--golos;
  color: $color-default-white;
  background-color: $color-medium-slate-blue;
  border-radius: 10px;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button--yonder {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.02em;
  font-family: $font-family--golos;
  color: $color-wild-blue-yonder;
  background-color: rgba($color-wild-blue-yonder, 0.2);
  box-shadow: none;

  &:disabled {
    color: $color-wild-blue-yonder;
    background-color: rgba($color-wild-blue-yonder, 0.2);
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button--transparent {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.02em;
  font-family: $font-family--golos;
  color: $color-medium-slate-blue;
  border: 2px solid $color-medium-slate-blue;
  background-color: $color-transparent;
  box-shadow: none;
  border-radius: 10px;
  padding: 6px 10px;
}
