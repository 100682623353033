.services-table {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.services-table__date {
  @include viewport--md {
    margin-bottom: 15px;
  }
  @include viewport--xsm {
    margin-bottom: 10px;
  }
}

.services-table__cell--index,
.services-table__name,
.services-table__price {
  margin: 0 0 12px;
  line-height: 19px;
  letter-spacing: 0.005em;
}
.services-table__name {
  &.without-margin {
    margin: 0!important;
  }
}
.services-table__address,
.services-table__station {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.services-table__row {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  padding: 24px 20px;
  border-bottom: 1px solid $color-gainsboro;

  @include viewport--xsm {
    flex-direction: column;
    padding: 24px 16px 22px;
  }
}

.services-table__part-wrapper {
  display: flex;
  max-width: calc(100% - 163px);
  // width: 100%;
  justify-content: space-between;
  width: 75%;
  margin-right: auto;

  @include viewport--md {
    flex-direction: column;
  }

  @include viewport--xsm {
    width: 100%;
    max-width: none;
  }
}

.services-table__cell--index {
  width: 20px;
  margin-right: 45px;

  @include viewport--md {
    margin-right: 30px;
  }

  @include viewport--xsm {
    display: none;
  }
}

.services-table__name-price-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 320px;
  width: 100%;
  margin-right: 20px;

  @include viewport--md {
    margin-bottom: 15px;
  }

  @include viewport--xsm {
    width: auto;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.services-table__cell--name-address {
  min-width: 250px;
  width: 100%;
  margin-right: 10px;

  @include viewport--xsm {
    min-width: 0;
    width: auto;
  }
}

.services-table__cell--price {
  min-width: 56px;
  white-space: nowrap;
  // display: flex;
  // justify-content: center;
  // width: 16.77%;
}

.services-table__tags-status-wrapper {
  display: flex;
  max-width: 505px;
  width: 100%;
  justify-content: space-between;

  @include viewport--md {
    justify-content: start;
    max-width: none;
    width: auto;
  }

  @include viewport--sm {
    flex-direction: column;
  }

  @include viewport--xsm {
    margin-bottom: 30px;
  }
}

.services-table__cell--tags {
  max-width: 300px;
  width: 100%;
  margin-right: 20px;

  @include viewport--md {
    max-width: 365px;
    width: auto;
    flex-grow: 2;
    margin-right: 10px;
  }

  @include viewport--sm {
    margin-bottom: 5px;
  }

  @include viewport--xsm {
    margin-right: 0;
  }
}

.services-table__statuses {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -2px;
  padding: 0;
}

.services-table__statuses-item {
  margin: 2px;
}

.services-table__cell--statuses {
  flex-grow: 0;
  flex-shrink: 0;
  width: 190px;
}

.services-table__cell--buttons {
  width: 163px;
  margin-top: -5px;
  margin-left: 30px;

  @include viewport--sm {
    margin-left: 15px;
  }

  @include viewport--xsm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
}

.services-table__edit {
  width: 163px;
  font-size: 13px;
  line-height: 20px;
  color: $color-default-black;
  align-self: flex-end;
  margin-bottom: 2px;

  @include viewport--xsm {
    margin-right: 10px;
  }
}

.services-table__copy-link {
  @include reset-btn();

  font-size: 13px;
  line-height: 20px;
  color: $color-default-black;
  padding: 5px;
  transition: all $default-transition-settings;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:focus {
    color: $color-han-purple;
  }
}
