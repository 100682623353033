.page-wrapper {
  width: 100%;
  position: relative;

  .container {
    min-height: 100%;
    padding: 0 142px;
    background-color: $color-alice-blue-2;

    @include viewport--lg {
      padding: 0 32px;
    }

    @include viewport--sm {
      padding: 0 16px;
      border-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__fixed-col {
    position: fixed;
    width: 33.333333%;
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));

    @include viewport--sm {
      width: 100%;
    }
  }

  &__bg {
    width: 100%;
    height: 100%;

    @include viewport--sm {
      height: 583px;
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay  {
    position: absolute;
    width: 25%;
    right: 0;
    height: 100%;
    z-index: 100;

    &.shadow {
      background: linear-gradient(270deg, rgba(119, 69, 255, 0.3) 0%, rgba(119, 69, 255, 0) 100%);
    }
  }

  &__logo {
    display: none;
    position: absolute;
    margin-top: 16px;
    margin-left: 24px;

    @include viewport--sm {
      display: block;
    }
  }

  &__main-col {
    height: 100%;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
    padding-left: 33.333333%;

    @include viewport--sm {
      position: relative;
      padding-left: 0;
      padding-top: 563px;
      z-index: 2;
    }

    &--full {
      @include viewport--sm {
        position: relative;
        padding-top: 0;
        z-index: 2;
      }

      .profile-header {
        @include viewport--sm {
          padding-top: 40px;
        }
      }

      .container {
        @include viewport--sm {
          border-radius: 0;
        }
      }
    }

    .container {
      position: relative;
    }
  }

  .profile-services {
    margin-top: 20px;
  }

  .logo {
    @include viewport--sm {
      display: none;
    }
  }
}
