.clients-filters {
  display: flex;
  align-items: flex-end;
  min-height: 33px;

  @include viewport--sm {
    display: block;
    margin-top: 13px;
  }
}

.clients-filters__search {
  width: 344px;
  margin-right: 16px;

  flex-shrink: 1;

  @include viewport--md {
    margin-right: 0;
    width: auto;
  }
}

.clients-filters__search .field-search {
  @include viewport--md {
    position: absolute;
    top: -62px;
    height: 61px;
    right: 74px;
    width: auto;
    max-width: none;

    &.active {
      position: absolute;
      top: -62px;
      left: 0;
      right: 0;
      height: 61px;
      padding: 0 15px;
    }
  }

  @include viewport--sm {
    height: 50px;
    top: -51px;
    right: 66px;

    &.active {
      height: 50px;
      top: -51px;
      right: 0;
    }
  }
}

.clients-filters__activity {
  width: 190px;
  margin-right: 24px;

  @include viewport--sm {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.clients-filters__status {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .field-checkbox {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 359px) {
    .field-checkbox {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
