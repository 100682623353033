.user-picture {
  width: 260px;

  @include viewport--xsm {
    background-color: $color-white-smoke;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

.user-picture__inner {
  position: relative;
}

.user-picture__img-wrapper {
  width: 260px;
  height: 434px;
  border-radius: 5px;

  @include viewport--xsm {
    width: 192px;
    height: 322px;
    margin: 0 auto;
  }
}

.user-picture__img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.user-picture__upload {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 220px;

  @include viewport--xsm {
    position: static;
    width: 192px;
    margin: 8px auto 0;
  }

  input[type="file"] {
    @include visually-hidden;

    &:focus + .user-picture__upload-label {
      background: $color-light-slate-blue;
    }
  }
}

.user-picture__upload-label {
  display: flex;
  padding: 8px 10px;
  border-radius: 6px;
  background-color: $color-han-purple;
  color: $color-default-white;
  text-transform: uppercase;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.5px;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.04),
    0 2.75px 9px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all $default-transition-settings;

  &:hover,
  &:focus,
  &:active {
    background-color: $color-light-slate-blue;
  }

  svg {
    margin-right: 6px;
  }
}

.user-picture__caption {
  font-size: 13px;
  line-height: 16px;
  color: $color-nero;
  padding: 0 20px;
  margin: 15px 0 0;

  @include viewport--xsm {
    text-align: center;
    padding-left: 32px;
    padding-right: 32px;
    max-width: 384px;
    margin: 15px auto 0;
  }
}
