.places-table {
  list-style: none;
  padding: 0;
  margin: 0;

  max-width: 100%;
  width: 100%;
}

.places-table__row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding: 20px;
  justify-content: space-between;
  min-height: 65px;

  @include viewport--sm {
    padding: 20px 16px;
  }

  @media (max-width: 359px) {
    display: block;
  }
}

.places-table__part {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.places-table__part--contacts {
  width: 78%;
  flex-shrink: 1;
  align-items: baseline;

  @media (max-width: 359px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.places-table__part--state {
  width: 22%;
  min-width: 270px;
  justify-content: flex-end;

  @include viewport--md {
    min-width: 210px;
  }

  @include viewport--sm {
    min-width: 200px;
  }

  @media (max-width: 359px) {
    width: 100%;
    justify-content: space-between;
  }
}

.places-table__cell {
  margin-right: 15px;
}

.places-table__cell--number {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;
  width: 58px;
  margin-right: 7px;

  flex-grow: 0;
  flex-shrink: 0;

  @include viewport--lg {
    width: 31px;
  }

  @include viewport--md {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    display: none;
  }

  @include viewport--sm {
    display: none;
  }
}

.places-table__cell--name {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;

  width: calc((100% - 65px) - 71%);

  @include viewport--lg {
    width: calc((100% - 38px) / 3);
  }

  @include viewport--md {
    font-size: 14px;
  }

  @include viewport--sm {
    width: 100%;
    margin-right: 10px;
    font-size: 16px;
  }
}

.places-table__cell--adress {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;

  width: calc((100% - 65px) - 63%);

  @include viewport--lg {
    width: calc((100% - 38px) / 3);
  }

  @include viewport--md {
    font-size: 14px;
  }

  @include viewport--sm {
    display: none;
  }
}

.places-table__cell--station {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;

  width: auto;
  flex-grow: 1;
  flex-shrink: 1;

  @include viewport--lg {
    width: calc((100% - 38px) / 3);
    flex-grow: 0;
    flex-shrink: 0;
  }

  @include viewport--md {
    font-size: 14px;
  }

  @include viewport--sm {
    display: none;
  }
}

.places-table__cell--state {
  min-width: 174px;

  @media (max-width: 1599px) {
    min-width: 130px;
  }

  @include viewport--md {
    min-width: 100px;
  }
}

.places-table__cell--actions {
  display: flex;
  margin-right: 0;

  .button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
