.profile-info {
  .title {
    margin-bottom: 12px;
  }

  &__contact {
    padding-top: 2px;
    border-top: 1px solid rgba($color-wild-blue-yonder, 0.1);

    @include viewport--sm {
      padding-top: 8px;
    }

    .paragraph {
      margin-bottom: 12px;
    }
  }
}
