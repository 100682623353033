.hover-ripple {
  transition: background-color $default-transition-settings;
  transform: translateZ(0);
  overflow: hidden;
}

.hover-ripple--han-purple {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      // background-color: $color-light-slate-blue;
      background-color: rgba($color-han-purple, 0.7);
    }
  }
}

.hover-ripple--silver {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-silver, 0.7);
    }
  }
}

.hover-ripple--gainsboro {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-gainsboro, 0.7);
    }
  }
}

.hover-ripple--magnolia {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-magnolia, 0.7);
    }
  }
}

.hover-ripple--air-force-blue {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-air-force-blue, 0.3);
    }
  }
}

.hover-ripple--mariner {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-mariner, 0.3);
    }
  }
}

.hover-ripple--chateau-green {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-chateau-green, 0.3);
    }
  }
}

.hover-ripple--whisper {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-whisper, 0.3);
    }
  }
}

.hover-ripple--purple-heart {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-purple-heart;
    }
  }
}

.hover-ripple--lucky-point {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-lucky-point;
      color: $color-lucky-point;
      background-color: $color-transparent;
    }
  }
}
