.address {
  display: flex;
  align-items: flex-start;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }
}
