.tabs__controls {
  display: flex;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid $color-white-smoke;

  @include viewport--sm {
    padding: 0 13px;
  }
}

.tabs__button {
  @include reset-btn;

  position: relative;
  z-index: 1;
  margin: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 15px;
  font-family: $font-family--base;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color-grey;
  outline: none;
  transition: color $default-transition-settings;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: $color-transparent;
    transition: background-color $default-transition-settings;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 12px;
    width: 0;
    height: 2px;
    background-color: $color-transparent;
    transition:
      background-color $default-transition-settings,
      width $default-transition-settings;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      &::before {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &:focus {
      color: $color-nero;

      // &::before {
      //   background-color: rgba($color-han-purple, 0.27);
      // }

      &::after {
        width: calc(100% - 24px);
        background-color: $color-han-purple;
      }
    }
  }
}

.tabs__button.active {
  color: $color-nero;

  &::after {
    width: calc(100% - 24px);
    background-color: $color-han-purple;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      &::before {
        background-color: rgba($color-han-purple, 0.25);
      }
    }
  }
}

.tabs__panel {
  display: none;
}

.tabs__panel.active {
  display: block;
}
