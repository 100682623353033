.tag-new {
  position: relative;
  padding-left: 10px;
  font-size: 14px;
  line-height: 24px;
  color: $color-han-purple;
}

.tag-new::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 10px;
  left: 0;
  background-color: $color-han-purple;
}
