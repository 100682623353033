.profile-social-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 2px solid $color-transparent;
  border-radius: 7px;
  background-color: $color-ghost-white;
  transition: border-color $default-transition-settings, box-shadow $default-transition-settings;

  &:hover,
  &:focus {
    border-color: $color-lucky-point;
    box-shadow: 0 4px 20px rgba($color-solitude, 0.6);
    transition: border-color $default-transition-settings, box-shadow $default-transition-settings;
  }

  svg {
    fill: none;
    color: $color-medium-slate-blue;
  }
}
