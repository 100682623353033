.link {
  @include reset-btn;
  text-align: left;
  color: $color-han-purple;
  border-radius: 4px;
  padding: 8px;
  transition: color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: rgba($color-han-purple, 0.7);
    }
  }

  &.hover-ripple {
    transition: color $button-transition-settings, background $button-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-default-white;
      }
    }
  }
}
