.booking {
  position: relative;

  &__header {
    padding: 18px;
    display: flex;
    align-items: center;
    font-family: $font-family--golos;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0 4px 6px rgba($color-jacksons-purple, 0.06);
    background-color: $color-default-white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: color $default-transition-settings;

    @include viewport--sm {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 10px 18px;
      min-height: 52px;
    }

    &:hover,
    &:focus {
      color: $color-purple-heart;
      transition: color $default-transition-settings;

      svg {
        color: $color-purple-heart;
        transition: color $default-transition-settings;
      }
    }

    svg {
      margin-right: 18px;
      color: $color-medium-slate-blue;
      transition: color $default-transition-settings;
    }
  }

  &__content {
    padding: 28px 0;
    padding-top: 24px;
    background-color: $color-medium-slate-blue;
    max-height: 512px;
    overflow-y: auto;

    &--group {
      max-height: 528px;
    }

    @include viewport--lg {
      max-height: calc(100vh - 48px - 240px);
    }

    @include viewport--sm {
      max-height: 100%;
      padding-top: 16px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__caption {
    margin: 0;
    margin-bottom: 4px;
    font-family: $font-family--golos;
    font-size: 12px;
    line-height: 16px;
    color: $color-wild-blue-yonder;

    &--no-appointments {
      display: none;
    }
  }

  .calendar-slider {
    margin-top: 8px;

    @include viewport--sm {
      margin-top: 12px;
    }
  }

  &__appointments {
    &--empty {
      .booking__caption {
        display: none;
      }

      .booking__caption--no-appointments {
        display: block;
        margin-bottom: 16px;
      }
    }
  }

  .button {
    span {
      margin-left: 8px;
    }
  }

  .appointment-list {
    margin-top: 4px;
    margin-bottom: 8px;

    @include viewport--sm {
      margin-bottom: 0;
    }

    &--one {
      @include viewport--sm {
        margin-bottom: 8px;
      }
    }
  }

  .custom-input--select {
    margin-bottom: 26px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__bottom {
    padding: 16px;
    box-shadow: 0 -1px 6px rgba($color-jacksons-purple, 0.15);
    background-color: $color-default-white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @include viewport--sm {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__form {
    padding: 0 16px;
    background-color: $color-alice-blue-2;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @include viewport--sm {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .form-field__inner textarea {
      height: 88px;
    }
  }

  &__success {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include viewport--sm {
      min-height: calc(100vh - 52px);
      padding-top: 80px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .title {
      margin-top: 16px;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;

      @include viewport--sm {
        flex-shrink: 0;
      }
    }

    .share {
      @include viewport--sm {
        flex-shrink: 0;
      }
    }

    .paragraph {
      max-width: 256px;
      margin-bottom: 48px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: $color-wild-blue-yonder;

      @include viewport--sm {
        flex-grow: 1;
      }

      span {
        color: $color-medium-slate-blue;
      }
    }
  }

  &__field {
    margin-bottom: 6px;

    &--checkbox {
      margin-top: 16px;

      label {
        align-items: flex-start;
      }
    }
  }

  &__field-link {
    color: $color-wild-blue-yonder;
    border-bottom: 1px solid $color-wild-blue-yonder;
    transition: opacity $default-transition-settings;

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity $default-transition-settings;
    }
  }


  &__field-buttons {
    display: flex;
    padding-bottom: 16px;
    margin-top: 16px;
  }

  &--check-out {
    .appointment {
      background-color: $color-default-white;
    }

    .field-checkbox__label {
      font-family: $font-family--golos;
      font-size: 12px;
      line-height: 16px;
      color: $color-wild-blue-yonder;
      flex-wrap: wrap;
    }

    .field-checkbox--public input:checked~.field-checkbox__icon {
      border: none;
    }

    .field-checkbox__icon {
      border: 1px solid $color-wild-blue-yonder;
      background-color: $color-default-white;
    }

    .booking__appointments {
      padding-top: 16px;
      padding-bottom: 9px;

      @include viewport--sm {
        padding-bottom: 16px;
      }
    }

    .button {
      &:last-child {
        margin-left: 8px;
        max-width: 97px;
      }
    }
  }

  &--success {
    min-height: 100%;
    border-radius: 20px;
    background-color: $color-alice-blue-2;

    @include viewport--sm {
      border-radius: 0;
    }

    .booking__header {
      color: $color-medium-slate-blue;
    }
  }
}
