.calendar-list {
  list-style: none;
  margin: 0;
  padding: 0 16px;

  &__item {
    margin-bottom: 14px;

    @include viewport--sm {
      margin-bottom: 14px;
    }
  }
}
