.item-adress {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.item-adress__content {
  padding-top: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-default-black;

  max-width: 230px;
}

.item-adress__pin {
  width: 51px;
  height: 51px;
  position: relative;
  display: block;
  border: 1px solid $color-white-smoke-2;
  border-radius: 10px;
  overflow: hidden;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: transform $default-transition-settings;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover img,
    &:focus img {
      transform: scale(1.1);
    }
  }
}
