.profile-socials {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-bottom: -8px;
  max-width: calc(220px + 40px);

  &__item {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &--white {
    .profile-social-btn {
      background-color: $color-default-white;
    }
  }
}
