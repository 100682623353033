// Colors
//---------------------------------
// https://www.htmlcsscolor.com/hex/334482

// Default

$color-default-black:             #000000;
$color-default-white:             #ffffff;
$color-transparent:               rgba(255, 255, 255, 0);

// Base

$color-han-purple:                #651fff;
$color-light-slate-blue:          #9362ff; // alpha 0.7 $color-han-purple
$color-medium-slate-blue:         #7745ff;
$color-purple-heart:              #6030e2;
$color-magnolia:                  #ede7f6;
$color-magnolia-2:                #f2f0f9;
$color-nero:                      #212121;
$color-nero-2:                    #111111;
$color-charcoal:                  #424242;
$color-dim-gray:                  #616161;
$color-grey:                      #757575;
$color-night-rider:               #333333;
$color-silver:                    #bdbdbd;
$color-whisper:                   #eeeeee;
$color-nobel:                     #9e9e9e;
$color-white-smoke:               #f1f1f1;
$color-snow:                      #fafafa;
$color-gainsboro:                 #e0e0e0;
$color-amour:                     #ffebee;
$color-we-peep:                   #ffcdd2;
$color-red-orange:                #f44336;
$color-corn-silk:                 #fff8e1;
$color-corn-field:                #fff5c0;
$color-golden-glow:               #ffe082;
$color-dark-orange:               #ff8f00;
$color-orange:                    #ffa800;
$color-alice-blue:                #e3f2fd;
$color-alice-blue-2:              #f8faff;
$color-tutu:                      #f9e3e3;
$color-aero-blue:                 #cbecd9;
$color-valencia:                  #db524e;
$color-eucalyptus:                #27ae60;
$color-air-force-blue:            #627da1;
$color-mariner:                   #4b629e;
$color-wild-blue-yonder:          #8189b2;
$color-chateau-green:             #28b446;
$color-grey:                      #828282;
$color-gainsboro-2:               #dadada;
$color-white-smoke:               #f5f5f5;
$color-white-smoke-2:             #f2f2f2;
$color-matterhorn:                #4f4f4f;
$color-mid-grey:                  #676a6c;
$color-matterhorn-2:              #505050;
$color-lucky-point:               #2f3357;
$color-solitude:                  #e6edf9;
$color-ghost-white:               #f6f8ff;
$color-hawkes-blue:               #d0d5ec;
$color-dodger-blue:               #0085ff;
$color-lavender:                  #e6e7F0;
$color-suva-grey:                 #8d8d8d;
$color-jacksons-purple:           #2c3796;


// Gradient

$black-to-right:                  linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

// elements
$border-color:                    $color-gainsboro;

// Typography
//---------------------------------

$font-family--base:                     "Roboto", "Arial", sans-serif;
$font-family--alt:                      "Montserrat", "Arial", sans-serif;
$font-family--golos:                    "Golos", "Arial", sans-serif;
$font-family--rouble:                   "Rouble", "Arial", sans-serif;

// Animation
//---------------------------------

$default-transition-settings:       0.3s ease;
$button-transition-settings:       0.8s ease;
// $vuetify-transition-settings:       0.2s cubic-bezier(0.4, 0, 0.6, 1);
$modal-transition-settings:         0.6s cubic-bezier(0.55, 0, 0.1, 1);

// Viewports
//---------------------------------

$viewport--sm:                      320px;
$viewport--375:                     375px;
$viewport--600:                     600px;
$viewport--md:                      767px;
$viewport--lg:                      961px;
$viewport--xl:                      1265px;
$viewport--xl-2:                    1371px;
$viewport--xxl:                     1920px;

// Retina
//---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;
