.day-time {
  width: 100%;
  position: relative;
}

.day-time__label {
  top: 5px;
  left: 7px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: rgba($color-nero-2, 0.48);
  position: absolute;
}

.day-time__fields {
  padding-top: 20px;

  display: flex;
}

.day-time__field--day {
  width: 137px;
}

.day-time__field--time {
  width: 70px;
}
