.profile-success {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  @include viewport--sm {
    padding-top: 132px;
  }

  .share {
    .profile-socials {
      justify-content: center;
    }

    @include viewport--sm  {
      flex-shrink: 0;
      // margin-bottom: 0;
      // margin-top: auto;
    }
  }

  .title {
    margin-top: 16px;
    margin-bottom: 48px;
    font-size: 20px;
    line-height: 26px;

    @include viewport--sm {
      // margin-bottom: 256px;
     flex-grow: 1;
      // margin-bottom: auto;
    }
  }
}
