.profile-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;

  @include viewport--sm {
    padding-bottom: 16px;
    padding-top: 40px;
  }

  &__bubble {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    background-color: $color-medium-slate-blue;
    border-radius: 10px;
    color: $color-default-white;
    font-family: $font-family--golos;
    font-size: 13px;
    line-height: 17px;

    @include viewport--sm {
      padding-top: 14px;
      padding-bottom: 18px;
    }
  }

  &__message {
    margin: 0;
    text-align: center;
    max-width: 256px;

    @include viewport--sm {
      max-width: 198px;
    }
  }

  &__link {
    margin-top: 4px;
    color: inherit;
    border-bottom: 1px solid rgba($color-default-white, 0.45);
    transition: opacity $default-transition-settings;

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity $default-transition-settings;
    }
  }

  &__icon {
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-medium-slate-blue;
  }

  &__copyright {
    margin: 0;
    font-family: $font-family--golos;
    font-size: 10px;
    line-height: 16px;
    color: $color-wild-blue-yonder;
  }
}
