.show-more {
  &__content {
    position: relative;
    max-height: 82px;
    overflow-y: hidden;

    @include viewport--sm {
      max-height: 183px;
    }

    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 48px;
      background: linear-gradient(to top, #FFFFFF 0%, $color-transparent 100%);
    }

    p {
      margin: 0;
      margin-bottom: 12px;
      font-family: $font-family--golos;
      font-size: 13px;
      line-height: 17px;
      color: $color-lucky-point;
    }
  }

  &__button {
    @include reset-btn;
    margin: 16px 0;
    font-family: $font-family--golos;
    font-size: 13px;
    line-height: 17px;
    color: $color-wild-blue-yonder;

    &:hover,
    &:focus {
      color: $color-lucky-point;
      transition: border-color $default-transition-settings, color $default-transition-settings;
    }

    span {
      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }
    }
  }

  &--active {
    .show-more__content {
      max-height: max-content;

      &::after {
        display: none;
      }
    }

    .show-more__button {
      span {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }
  }
}
