.contact-btn {
  @include reset-btn;
  display: inline-block;
  line-height: 0;
  flex-grow: 0;
  flex-shrink: 0;
  transition: opacity $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.85;
    }
  }
}

.contact-btn--telegram {
  color: #039BE5;
}

.contact-btn--whatsapp {
  color: #2CB742;
}

.contact-btn--phone {
  color: #844CFF;
}
