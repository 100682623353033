.field-switch {
  width: 100%;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }

  input {
    outline: 0;
    overflow: hidden;
  }

  input:hover ~ .field-switch__toggle,
  input:focus ~ .field-switch__toggle {
    &::after {
      background-color: rgba($color-whisper, 0.7);
    }
  }

  input:checked:hover ~ .field-switch__toggle,
  input:checked:focus ~ .field-switch__toggle {
    &::after {
      background-color: rgba($color-han-purple, 0.2);
    }
  }

  input:checked ~ .field-switch__toggle {
    background-color: $color-han-purple;

    &::before {
      left: calc(100% - 20px);
    }

    &::after {
      left: calc(100% - 30px);
    }
  }
}

.field-switch.error {
  .field-text__error-message {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    bottom: 0;
    padding-left: 1rem;
  }
}

.field-switch__label {
  margin-right: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;
  user-select: none;
}

.field-switch__toggle {
  flex-shrink: 0;
  position: relative;
  width: 36px;
  height: 15px;
  border-radius: 15px;
  background-color: rgba($color-matterhorn, 0.7);
  transition: background-color $default-transition-settings;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $color-default-white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    transition: left $default-transition-settings;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    z-index: -1;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;

    transition: background-color $default-transition-settings;
  }
}
