.page-title {
  margin: 0;
  font-family: $font-family--alt;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: $color-default-black;

  .page--notes & {
    @include viewport--sm {
      display: none;
    }
  }
}
