.clients-table {
  list-style: none;
  padding: 0;
  margin: 0;

  max-width: 100%;
  width: 100%;
}

.clients-table__row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding: 20px;
  justify-content: space-between;
  min-height: 65px;

  @include viewport--md {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include viewport--sm {
    padding: 20px 16px;
  }
}

.clients-table__part {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.clients-table__part--contacts {
  width: 48.5%;
  min-width: 490px;

  @media (max-width: 1365px) {
    min-width: 440px;
  }

  @include viewport--md {
    width: 60%;
    min-width: 10px;
  }

  @media (max-width: 799px) {
    width: 52%;
  }

  @include viewport--sm {
    width: 100%;
    margin-bottom: 10px;
  }
}

.clients-table__part--comment {
  width: auto;
  justify-content: center;

  flex-shrink: 1;
  flex-grow: 1;

  @include viewport--md {
    justify-content: flex-start;
    padding-left: 38px;
    order: 2;
    width: 100%;
    margin-top: 15px;
  }

  @include viewport--sm {
    padding-left: 0;
  }
}

.clients-table__part--state {
  width: 24.5%;
  min-width: 315px;
  justify-content: flex-end;

  @media (max-width: 1365px) {
    min-width: 280px;
    justify-content: space-between;
  }

  @include viewport--md {
    width: 40%;
    min-width: 10px;
    justify-content: flex-end;
  }

  @media (max-width: 799px) {
    width: 48%;
  }

  @include viewport--sm {
    width: 47%;
    min-width: 10px;
    justify-content: space-between;
  }
}

.clients-table__cell {
  margin-right: 15px;
}

.clients-table__cell--number {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;
  width: 31px;
  margin-right: 7px;

  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 1365px) {
    font-size: 14px;
  }

  @include viewport--sm {
    display: none;
  }
}

.clients-table__cell--name {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;
  min-width: 240px;
  max-width: 300px;

  flex-grow: 1;
  flex-shrink: 1;

  span {
    white-space: nowrap;
    position: relative;
    top: 2px;
  }

  svg {
    color: $color-han-purple;
  }

  @media (max-width: 1600px) {
    min-width: 200px;
  }

  @media (max-width: 1365px) {
    font-size: 14px;
    min-width: 170px;
  }

  @include viewport--sm {
    span {
      word-break: break-all;
    }
  }
}

.clients-table__cell--state {
  min-width: 130px;
  width: 10%;

  @media (max-width: 1550px) {
    min-width: 110px;
  }

  @media (max-width: 1365px) {
    min-width: 100px;
  }

  @include viewport--sm {
    display: none;
  }
}

.clients-table__cell--phone {
  flex-grow: 0;
  flex-shrink: 0;
  width: 115px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: $color-charcoal;

  @media (max-width: 1365px) {
    font-size: 14px;
    width: 90px;
  }

  @media (max-width: 799px) {
    display: none;
  }
}

.clients-table__cell--contacts {
  display: flex;
  justify-content: space-between;
  width: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 30px;

  @media (max-width: 1365px) {
    // width: 65px;
  }

  @media (max-width: 799px) {
    // display: none;
  }
}

.clients-table__cell--form {
  flex: 0 0 90px;
  padding-top: 26px;
  margin-right: 16px;
}

.client-has-phone {
  display: flex!important;
  justify-content: space-between;

  input {
    width: initial;
  }
  svg {
    position: initial;
    top: initial;
    right: initial; 
    color: inherit;
    opacity: initial;
    visibility: initial;
    pointer-events: none;
    transition: initial;
  }
}

.clients-table__cell--actions {
  display: flex;
  margin-right: 0;

  .button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.clients-table__cell--payment-comment {
  font-size: 14px;
  line-height: 24px;
  color: $color-charcoal;

  span {
    display: block;
    position: relative;
    padding-left: 16px;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      left: 0;
      position: absolute;
      background: $color-han-purple;
      border-radius: 50%;
      top: 8px;
    }
  }

  @include viewport--md {
    font-size: 13px;
  }
}
