.field-text {
  position: relative;
  &.slot {
    padding: 20px 10px;
    .field-text__error-message {
      position: initial;
      text-align: center;
    }
  }
  &.form-field {
    .field-text__error-message {
      position: initial;
      margin-top: 5px;
    }
  }
  label {
    position: relative;
    display: block;
    width: 100%;
  }

  input,
  textarea {
    width: 100%;
    padding: 26px 16px 6px;
    padding-right: 48px;
    cursor: pointer;
    appearance: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-nero-2;
    border: none;
    background-color: $color-default-white;
    box-shadow:
      0 4px 4px rgba(51, 51, 51, 0.04),
      0 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 4px;
    outline: none;
    transition: box-shadow $default-transition-settings;
  }

  textarea {
    display: block;
  }

  input:hover,
  input:focus,
  textarea:hover,
  textarea:focus {
    box-shadow:
      0 4px 4px rgba(51, 51, 51, 0.04),
      0 4px 24px rgba(51, 51, 51, 0.24);
  }

  input:focus,
  textarea:focus {
    cursor: text;
  }


  input:disabled,
  textarea:disabled {
    cursor: auto;
    color: rgba($color-nero-2, 0.24);
  }

  input:disabled:hover,
  input:disabled:focus,
  textarea:disabled:hover,
  textarea:disabled:focus {
    box-shadow:
      0 4px 4px rgba(51, 51, 51, 0.04),
      0 4px 16px rgba(51, 51, 51, 0.08);
  }

  input:disabled + span,
  textarea:disabled + span {
    opacity: 0.24;
    // cursor: auto;
  }

  input::placeholder,
  textarea::placeholder {
    color: transparent;
  }

  input + span,
  textarea + span {
    top: 15px;
    left: 15px;
    font-size: 16px;
    line-height: 24px;
  }

  input:focus + span,
  textarea:focus + span {
    top: 5px;
    left: 15px;
    font-size: 13px;
    line-height: 20px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    box-shadow:
      0 0 0 1000px $color-default-white inset,
      0 4px 4px rgba(51, 51, 51, 0.04),
      0 4px 16px rgba(51, 51, 51, 0.08);
    transition: background-color $default-transition-settings;
  }

  svg {
    position: absolute;
    top: 22px;
    right: 16px;
    color: $color-eucalyptus;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
      opacity $default-transition-settings,
      visibility $default-transition-settings;
  }

  span {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba($color-nero-2, 0.48);
    // cursor: pointer;
    pointer-events: none;
    transition:
      top $default-transition-settings,
      font-size $default-transition-settings,
      line-height $default-transition-settings;
  }
}

.field-text.has-value {
  input + span,
  textarea + span {
    top: 5px;
    left: 15px;
    font-size: 13px;
    line-height: 20px;
  }
}

.field-text--border {
  input,
  textarea {
    padding: 0;
    padding-bottom: 7px;
    padding-right: 48px;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid $color-silver;
    transition:
      border-color $default-transition-settings,
      border-width $default-transition-settings;
  }

  input:hover,
  textarea:hover {
    box-shadow: none;
    border-color: $color-charcoal;
  }

  input:focus,
  textarea:focus {
    box-shadow: none;
    border-color: $color-charcoal;
  }

  input:placeholder-shown + span,
  textarea:placeholder-shown + span {
    top: 0;
    left: 0;
    font-size: 14px;
    line-height: 24px;
  }

  input:not(:placeholder-shown) + span,
  input:focus + span,
  textarea:not(:placeholder-shown) + span,
  textarea:focus + span {
    color: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px $color-default-white inset;
    transition: background-color $default-transition-settings;
  }


  span {
    font-size: 14px;
    line-height: 24px;
    color: $color-charcoal;
    transition: color $default-transition-settings;
  }
}

.field-text__error-message {
  position: absolute;
  bottom: -25px;
  left: 0;
  z-index: 1;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-valencia;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    opacity $default-transition-settings,
    visibility $default-transition-settings;
}

.field-text.error {
  input,
  textarea {
    background-color: $color-tutu;
    box-shadow:
      0 4px 4px rgba(255, 196, 196, 0.04),
      0 4px 12px rgba(255, 196, 196, 0.24);
  }

  .field-text__error-message {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.field-text.success {
  input,
  textarea {
    background-color: $color-aero-blue;
    box-shadow:
      0 4px 4px rgba(39, 174, 96, 0.04),
      0 4px 40px rgba(39, 174, 96, 0.24);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    box-shadow:
      0 0 0 1000px $color-default-white inset,
      0 4px 4px rgba(51, 51, 51, 0.04),
      0 4px 16px rgba(51, 51, 51, 0.08);
    transition: background-color $default-transition-settings;
  }

  svg {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.field-text input::-webkit-outer-spin-button
.field-text input::-webkit-inner-spin-button {
  appearance: none;
}

.field-text input[type="number"] {
  appearance: textfield;
}

.field-text--price {
  position: relative;

  .field-text__icon-rouble {
    position: absolute;
    color: $color-nero-2;
    visibility: visible;
    opacity: 1;
    left: 55px;
    top: 28px;
  }
}
.field-text__success-message {
  margin: 0;
  color: #27ae60;
}
