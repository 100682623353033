.field-checkbox {
  display: flex;

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    cursor: pointer;
    appearance: none;
  }

  input:hover ~ .field-checkbox__icon,
  input:focus ~ .field-checkbox__icon {
    &::after {
      opacity: 1;
    }
  }

  input:checked ~ .field-checkbox__icon {
    svg {
      opacity: 1;
      visibility: visible;
    }
  }
}

.field-checkbox__label {
  position: relative;
  display: inline-flex;
  padding-left: 12px;
  font-size: 14px;
  line-height: 16px;
  color: $color-night-rider;
  &.small {
    @media (min-width: 799px) {
      font-size: 12px;
      min-width: 70px;
    }
    
  }
}


.field-checkbox__icon {
  position: relative;
  display: inline-flex;

  svg {
    color: $color-han-purple;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
      opacity $default-transition-settings,
      visibility $default-transition-settings;
  }

  &--small {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid $color-han-purple;
    // background-color: transparent;
    // transition: background-color $default-transition-settings;
  }

  &::after {
    content: "";
    position: absolute;
    top: -11px;
    left: -11px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba($color-han-purple, 0.2);
    opacity: 0;
    transition: opacity $default-transition-settings;
  }
}

.field-checkbox--public {
  input:checked ~ .field-checkbox__icon {
    background-color: $color-default-white;
  }

  input:hover ~ .field-checkbox__icon,
  input:focus ~ .field-checkbox__icon {
    &::before {
      transition: border-color $default-transition-settings;
      border-color: $color-default-white;
    }
  }

  .field-checkbox__icon {
    display: none;

    &::before {
      background-color: rgba($color-default-white, 0.15);
      border: 1px solid rgba($color-default-white, 0.45);
      transition: border-color $default-transition-settings;
    }

    &::after {
      display: none;
    }

    &--small {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      color: $color-default-white;

      svg {
        fill: none;
      }
    }
  }
}

.field-checkbox--purple {
  input:checked ~ .field-checkbox__icon {
    background-color: $color-medium-slate-blue;
  }

  .field-checkbox__icon {
    &--small {
      svg {
        color: $color-default-white;
      }
    }
  }
}
