.tag-list {
  margin: 0;
  margin-bottom: -4px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__item {
    margin-right: 4px;
    margin-bottom: 2px;
  }
}
