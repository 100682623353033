.copy-popup {
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 5;
  width: 215px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 20px;
  padding-bottom: 30px;
  transform: translateX(-50%);
  background-color: $color-default-white;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  transition:
    opacity $default-transition-settings,
    visibility $default-transition-settings;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.copy-popup.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.copy-popup__title {
  margin: 0;
  margin-bottom: 27px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
}

.copy-popup__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 32px;
}

.copy-popup__item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
