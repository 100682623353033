.data-tabs {
  padding: 10px 0;
}

.data-tabs__controls {
  margin-bottom: 12px;
  padding: 0 16px;

  @include viewport--sm {
    justify-content: center;
  }
}

.data-tabs__panels {
  position: relative;
}
