.contacts-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.contacts-block__title {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-default-black;
  margin-right: 20px;
}

.contacts-block__list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.contacts-block__item {
  text-align: center;
  margin: 6px 17px 6px 0;
  line-height: 0;

  &:last-child {
    margin-right: 0;
  }
}

.contacts-block__item-caption {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.015em;
  color: $color-grey;
  margin-top: 5px;
}
