.authorization {
  width: 475px;
  margin: 0 auto;

  background: $color-default-white;
  box-shadow:
    0 4px 4px rgba(51, 51, 51, 0.04),
    0 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;

  @include viewport--sm {
    width: 100%;
    max-width: 475px;
  }
}

.authorization__wrapper {
  padding-top: 36px;
  padding-left: 32px;
  padding-right: 32px;

  @include viewport--sm {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.authorization__logo {
  margin-bottom: 14px;

  svg {
    color: $color-default-black;
  }
}
