.records-filters {
  display: flex;
  width: 100%;
}

.records-filters__btn-open {
  @include reset-btn;

  display: none;
  font-family: $font-family--base;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;

  @include viewport--md {
    display: flex;
    align-items: center;
    width: 100%;
  }

  svg {
    margin-right: 2px;
    transform: rotate(-90deg);
  }
}

.records-filters__btn-text {
  margin-right: 10px;
  color: $color-default-black;
}

.records-filters__btn-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: auto;
  border-radius: 50%;
  color: $color-default-white;
  background-color: $color-han-purple;
  box-shadow:
    0 0.25px 3px rgba(0, 0, 0, 0.039),
    0 2.75px 9px rgba(0, 0, 0, 0.19);
}

.records-filters__wrapper {
  display: flex;
  align-items: flex-end;

  @include viewport--md {
    position: fixed;
    top: 53px;
    left: 0;
    z-index: 2;
    width: 100%;
    min-height: calc(100% - 53px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $color-default-white;
    transition:
      opacity $default-transition-settings,
      visibility $default-transition-settings;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  @include viewport--sm {
    top: 0;
    min-height: 100%;
  }
}

.records-filters__wrapper.active {
  @include viewport--md {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.records-filters__header {
  display: none;

  @include viewport--md {
    position: relative;
    display: block;
    width: 100%;
    padding: 14px 93px;
    border-bottom: 1px solid $color-gainsboro;
    text-align: center;
  }
}

.records-filters__btn-back {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);

  svg {
    margin-right: 9px;
  }
}

.records-filters__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
}

.records-filters__btn-close {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}

.records-filters__inner {
  display: flex;
  align-items: center;
  width: 100%;

  @include viewport--md {
    flex-direction: column;
  }
}

.records-filters__period {
  width: 220px;
  margin-right: 16px;

  @include viewport--md {
    width: 100%;
    margin-right: 0;
    padding: 0 16px;
    padding-top: 18px;
    border-bottom: 1px solid $color-gainsboro;
  }
}

.records-filters__client {
  width: 190px;
  margin-right: 16px;

  @include viewport--md {
    width: 100%;
    margin-right: 0;
    border-bottom: 1px solid $color-gainsboro;
  }
}

.records-filters__place {
  width: 190px;
  margin-right: 16px;

  @include viewport--md {
    width: 100%;
    margin-right: 0;
    border-bottom: 1px solid $color-gainsboro;
  }
}

.records-filters__status {
  align-self: center;

  @include viewport--md {
    align-self: flex-start;
    width: 100%;
    margin-right: 0;
    padding: 22px 16px;
    border-bottom: 1px solid $color-gainsboro;
  }
}

.records-filters__btn-submit {
  display: none;
  width: 100%;
  margin-top: auto;

  @include viewport--md {
    display: block;
    padding: 32px 16px;
  }
}
