.calendar-init {
  position: relative;

  input {
    position: relative;
    z-index: 4;
    display: block;
    width: 100%;
    min-height: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0;
    padding-right: 15px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-night-rider;
    background-color: $color-transparent;
    border: none;
    border-bottom: 1px solid $color-silver;
    border-radius: 0;
    outline: none;

    transition: color $default-transition-settings, border $default-transition-settings;

    &:hover,
    &:focus {
      border-color: $color-night-rider;
    }

    @include viewport--sm {
      line-height: 24px;
    }
  }

  input::placeholder {
    color: $color-transparent;
  }

  input ~ label {
    top: 0;
    left: 0;
    font-size: 16px;
    line-height: 24px;
  }

  input:focus ~ label {
    top: -15px;
    left: 0;
    font-size: 12px;
    line-height: 16px;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    pointer-events: none;
    transition:
      top $default-transition-settings,
      font-size $default-transition-settings,
      line-height $default-transition-settings;

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .flatpickr-input.active ~ .calendar-init__dropdown-icon {
    transform: translateY(-50%) rotate(-180deg);
    color: $color-night-rider;
  }


  // Стили календаря
  //-------------------

  .flatpickr-calendar {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.calendar-init.has-value {
  input ~ label {
    top: -15px;
    left: 0;
    font-size: 12px;
    line-height: 16px;
  }
}

.calendar-init--range {
  input {
    @include viewport--md {
      padding-top: 12px;
      padding-bottom: 12px;
      border: none;

      &:hover,
      &:focus {
        border: none;
      }
    }
  }

  input:focus ~ label {
    @include viewport--md {
      top: -6px;
      left: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }

  label {
    @include viewport--md {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .calendar-init__dropdown-icon {
    @include viewport--md {
      top: 10px;
      width: 12px;
      height: 8px;
      color: $color-default-black;
      transform: rotate(-90deg);
    }
  }

  .flatpickr-input.active ~ .calendar-init__dropdown-icon {
    @include viewport--md {
      transform: rotate(0deg);
    }
  }
}

.calendar-init--range.has-value {
  input ~ label {
    @include viewport--md {
      top: -6px;
      left: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.calendar-init--inline {
  .flatpickr-input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    min-height: 1px;
    clip: rect(0 0 0 0);
  }

  .calendar-init__dropdown-icon {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);
  }
}

.calendar-init--date {
  input {
    padding: 5px 7px 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    border-radius: 0;
    border-bottom: 1px solid $color-transparent;
    transition: border-color $default-transition-settings;

    &:hover,
    &:focus {
      border-color: $color-whisper;
    }
  }

  label {
    color: $color-transparent;
  }

  .flatpickr-input.active {
    border-color: $color-whisper;
  }

  .calendar-init__dropdown-icon {
    display: none;
  }
}

.calendar-init__dropdown-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  transition: color $default-transition-settings, transform $default-transition-settings;
  pointer-events: none;
  color: $color-default-black;
}

.calendar-init__period-box {
  display: none;

  @include viewport--sm {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    padding: 15px;

    background-color: $color-default-white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity $default-transition-settings, visibility $default-transition-settings;
  }
}
